import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

const CopyButton = ({ children = null, toCopy }) => {
  // As of 2024-03-13, this is the correct way to write to the clipboard in all major browsers, but
  // the browsers are not conforming to the W3C specification when it comes to permissions. This
  // may result in a bug in the future, but browser support for the relevant permissions does not
  // exist right now so this is the best we can do.
  // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#extensions_to_other_interfaces
  const onClick = () => {
    const writeResult =
      typeof toCopy === 'string'
        ? navigator.clipboard.writeText(toCopy)
        : navigator.clipboard.write([toCopy]);
    writeResult.catch(err => {
      // eslint-disable-next-line no-console
      console.error('Failed to copy to clipboard:', err, toCopy);
      message.error(t`Failed to copy to clipboard`);
    });
  };

  return (
    <Button icon={<CopyOutlined />} onClick={onClick} style={children ? null : { float: 'right' }}>
      {children}
    </Button>
  );
};

CopyButton.propTypes = {
  /**
   * If you do not pass children, the button will float to the right as a helper button instead of
   * acting as a Call To Action
   */
  children: PropTypes.node,
  /**
   * You can pass a string of plain text or a ClipboardItem object containing other formats, such
   * as formatted HTML with a plain text fallback
   */
  // This should actually check for PropTypes.instanceOf(ClipboardItem) instead of PropTypes.object
  // but JSDom and @testing-library/user-event don't play nicely with this assertion, because
  // user-event only stubs it when its called while we need it globally stubbed for proptypes
  toCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default CopyButton;
