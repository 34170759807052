import {
  Alert,
  Button,
  Checkbox,
  DatePicker as AntDatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select as AntSelect,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import styled, { createGlobalStyle } from 'styled-components';
import { jt, t } from 'ttag';

import Header from 'common/components/Header';
import ImageUpload from 'common/components/ImageUpload';
import Label from 'common/components/Label';
import RichTextEditor from 'common/components/RichTextEditor';
import StickyFooter from 'common/components/StickyFooter';
import Url, { UrlTypes } from 'common/components/Url';
import useConfig from 'common/hooks/useConfig';
import useTranslation from 'common/hooks/useTranslation';
import { updateCmsEvent as updateFn } from 'common/services/events/api';
import { SUPPORT_EMAIL, SALES_EMAIL } from 'common/services/support/types';
import { getEvent, setEvent as setGlobalEvent } from 'common/state/event';
import { isEnabled as isFlagEnabled } from 'common/state/flags';

import GoogleMap from '@components/GoogleMap';
import { STATUS_ACTIVE } from '@domains/Organization/contracts-service';
import { useContracts } from '@domains/Organization/DataUsage/PeopleCredit/queries';
import usePermission, { UPDATE_EVENT_ATTRIBUTES_UNRESTRICTED } from '@hooks/usePermission';

import EmailField from '../EmailField';
import { list as listLanguages } from '../language-service';
import { Field, Description } from '../StyledComponents';
import TZ from '../timezone-service';

const FORMAT_DATE = 'YYYY-MM-DD';
const LOCALE_DATE = 'L';

const TIMEZONES = TZ();

const ERROR_MESSAGES_BY_CODE = () => ({
  '62601618-03db-4a2e-913b-02f978d2b3f5': t`As your event has ended, further updates to its Event Details are no longer possible.`,
  'f19564a1-376b-4a1c-b9d4-03f4e2a8c999': t`Event duration is more than max event duration.`,
});

const EVENT_MAX_DURATION_CONFIG = 'event_max_duration_days';
const CONF_IS_USER_CREDIT_EVENT = 'is_user_credit_event';

// Ant Aliases
const { Text } = Typography;
const { RangePicker: AntRangePicker } = AntDatePicker;
const { Option } = AntSelect;
const { useForm, useWatch } = Form;

// Styled Components
const Wrap = styled.div`
  margin: 0 auto 50px;
  padding-bottom: 86px;
`;

const Select = styled(AntSelect)`
  width: 100%;
`;

const RangePicker = styled(AntRangePicker)`
  width: 100%;
`;

// overriding styles for range picker popup for this component
const SupportPickerStyles = createGlobalStyle`
  .ant-picker {
    .ant-picker-input > input {
      text-align: center;
    }

    .ant-calendar-range-middle {
      padding: 0;
    }

    border-color: #d41968;

    &:hover {
      border-color: #d41968;
      box-shadow: 0 0 0 2px rgb(212 25 104 / 20%);
    }
  }

  .ant-picker-focused {
    border-color: #d41968;
    box-shadow: 0 0 0 2px rgb(212 25 104 / 20%);
  }

  .ant-picker-range .ant-picker-active-bar {
    background: #d41968;
  }

  .ant-picker-panels {
    width: 600px;
  }
`;

const PickerPopupStyles = createGlobalStyle`
  .ant-picker {
    .ant-picker-input > input {
      text-align: center;
    }

    .ant-calendar-range-middle {
      padding: 0;
    }
  }

  .ant-picker-panels {
    width: 600px;
  }`;

const RangePickerField = styled(Field)`
  && {
    padding: 0;
    margin-bottom: 0;
  }
`;

const getContactSalesLink = () => (
  <Typography.Link
    key="salesEmail"
    href={`mailto:${SALES_EMAIL}?subject=Inquiry for Contract Extension – People Credit License`}
    target="_blank"
    rel="noreferrer noopener"
  >
    {t`Contact Sales`}
  </Typography.Link>
);

const formatPeriodStartAndEndDates = (date, key) => (
  <Typography.Text strong type="secondary" key={key}>
    {date.format(LOCALE_DATE)}
  </Typography.Text>
);

const getMultipleContractsMessage = () => (
  <Typography.Text type="secondary">
    {t`Your event exceeds the current contract period. Additional credits will be used from each contract the event spans.`}
  </Typography.Text>
);

const getNoContractPeriodMessage = (periodStartDate, periodEndDate) => {
  const startDate = formatPeriodStartAndEndDates(periodStartDate, 'start-date-text');
  const endDate = formatPeriodStartAndEndDates(periodEndDate, 'end-date-text');
  const contactSalesLink = getContactSalesLink();

  return (
    <Typography.Text type="secondary">
      {jt`Your event has dates outside the contract period. Attendees cannot access the event during ${startDate} to ${endDate}. ${contactSalesLink}.`}
    </Typography.Text>
  );
};

const getMultipleContractsAndNoContractPeriodMessage = (periodStartDate, periodEndDate) => {
  const startDate = formatPeriodStartAndEndDates(periodStartDate, 'start-date-text');
  const endDate = formatPeriodStartAndEndDates(periodEndDate, 'end-date-text');
  const contactSalesLink = getContactSalesLink();

  return (
    <Typography.Text type="secondary">
      {jt`Your event dates exceed the current contract period and additional credits will be used for each contract the event spans. Additionally, attendees will not have access to your event space from ${startDate} to ${endDate} because the event has dates outside the contract period. ${contactSalesLink}.`}
    </Typography.Text>
  );
};

const determineBannerMessage = eventPeriods => {
  const noContractPeriods = eventPeriods.filter(period => period.noContract);

  if (eventPeriods.length <= 1) {
    // If there is only one period and it's a CONTRACT period, return null.
    if (!noContractPeriods.length) {
      return null;
    }
    // If there is only one period and it's a NO-CONTRACT period, return no-contract-period message.
    return getNoContractPeriodMessage(noContractPeriods[0].start, noContractPeriods[0].end);
  }

  if (!noContractPeriods.length) {
    // If there are multiple periods and all are CONTRACT periods, return multiple-contracts message.
    return getMultipleContractsMessage();
  }

  if (eventPeriods.length === 2 && noContractPeriods.length === 1) {
    // If there is only one CONTRACT period and only one NO-CONTRACT period, return no-contract-period message.
    return getNoContractPeriodMessage(noContractPeriods[0].start, noContractPeriods[0].end);
  }

  // If there are N number of CONTRACT periods and at least 1 NO-CONTRACT period; where N > 1, return multiple-contracts-and-no-contract-period message.
  // Use dates of the NO-CONTRACT period in the nearest future.
  const closestNoContractPeriod = noContractPeriods.find(period =>
    moment().isSameOrBefore(period.start)
  );
  return getMultipleContractsAndNoContractPeriodMessage(
    closestNoContractPeriod.start,
    closestNoContractPeriod.end
  );
};

const getEventPeriods = (contracts, eventStartDate, eventEndDate) => {
  const eventPeriods = [];
  // Start from the latest of the event start date and today
  let currentStart = moment.max(eventStartDate, moment());

  contracts.forEach(contract => {
    const contractStartDate = moment(contract.startDate);
    const contractEndDate = moment(contract.endDate);

    // Stop if we have reached the event end date
    if (currentStart.isAfter(eventEndDate)) return;

    // If there's a gap between the current start and the contract start date, add a no-contract period
    if (contractStartDate.diff(currentStart, 'days') > 0) {
      // We do not need to consider the contracts after the event end date
      const endOfNoContractPeriod = moment.min(
        contractStartDate.clone().subtract(1, 'days'),
        eventEndDate
      );

      eventPeriods.push({
        start: currentStart,
        end: endOfNoContractPeriod,
        noContract: true,
      });
      currentStart = contractStartDate;
    }

    // If the event is entirely within the contract period and we have not already reached the event end date, add a contract period
    if (contractEndDate.isSameOrAfter(currentStart) && currentStart.isSameOrBefore(eventEndDate)) {
      // We do not need to consider the contracts after the event end date
      const endOfContractPeriod = moment.min(contractEndDate, eventEndDate);

      eventPeriods.push({
        start: currentStart,
        end: endOfContractPeriod,
        noContract: false,
      });
      currentStart = endOfContractPeriod.clone().add(1, 'days');
    }
  });

  // Add any remaining period after the last contract ends as a no-contract period
  if (currentStart.isSameOrBefore(eventEndDate)) {
    eventPeriods.push({
      start: currentStart,
      end: eventEndDate,
      noContract: true,
    });
  }

  return eventPeriods;
};

const getBannerMessage = (contracts, eventStartDate, eventEndDate) => {
  // Banner Message Key: https://www.figma.com/board/UiTUgve7UYMvX5kTfX8M6c/UCL-2.0?node-id=225-431&t=2MarqIQyMyFSz1AZ-4

  const eventStart = moment(eventStartDate);
  const eventEnd = moment(eventEndDate);

  const eventPeriods = getEventPeriods(contracts, eventStart, eventEnd);
  const bannerMessage = determineBannerMessage(eventPeriods);

  return bannerMessage;
};

const useLanguages = () => {
  const { locale } = useTranslation();

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLanguages(listLanguages(true, locale.toString().replace('_', '-')));
  }, [locale]);

  return languages;
};

const searchOptionByLabel = (inputValue, option) =>
  option.key.toLowerCase().includes(inputValue.toLowerCase());

// This is to control when to show the warning modal for a changed language. The modal needs to
// be shown if both languages were already set, and then one or more of the following happens:
// 1. Primary language is changed
// 2. Secondary language is changed
// 3. Secondary language is removed
const useDirtyLanguage = () => {
  const [value, setValue] = useState(false);

  const onChange = (condition = true) => {
    if (!value && condition) {
      setValue(true);
    }
  };

  return { value, onChange, reset: () => setValue(false) };
};

const selector = createSelector(getEvent, event => ({
  name: event.name,
  shortcode: event.shortcode,
  description: event.description,

  eventWebsite: event.eventWebsite,
  language: event.language,
  alternativeLanguages: event.alternativeLanguages,
  multilingualEventapp: event.multilingualEventApp,
  eventIconImageId: event.eventIconImageId,

  useCustomOutboundEmailAddress: event.useCustomOutboundEmailAddress,
  outboundEmailAddress: event.outboundEmailAddress,
  supportEmailAddress: event.supportEmailAddress,

  startDate: event.startDate,
  endDate: event.endDate,

  timezone: event.timezone,
  timeFormat: event.timeFormat,

  locationName: event.locationName,
  locationAddress: event.locationAddress,
}));

const EventInfo = () => {
  const { organizationId, eventId } = useParams();
  const { userLanguage, ...initial } = useSelector(selector);
  const [isEmailDomainFFEnabled] = useSelector(state => [
    isFlagEnabled(state, 'exp_email_spam_improvements'),
  ]);

  const { authorized } = usePermission();

  const dispatch = useDispatch();

  const [form] = useForm();

  const [event, setEvent] = useState(initial);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [bannerMessage, setBannerMessage] = useState(null);
  const [showSecondaryLanguage, setShowSecondaryLanguage] = useState(
    event?.alternativeLanguages?.length > 0
  );

  const conf = useConfig();

  const { data: contracts } = useContracts(organizationId, {
    sort: 'start_date',
  });

  const unrestrictedAccess = authorized(UPDATE_EVENT_ATTRIBUTES_UNRESTRICTED);

  const eventMaxDurationConfig = conf.get(EVENT_MAX_DURATION_CONFIG);
  const isUserCreditEvent = conf.isEnabled(CONF_IS_USER_CREDIT_EVENT);
  const hasExpired = moment().isAfter(moment(event.endDate), 'day');
  const hasStarted = moment().isSameOrAfter(moment(event.startDate), 'day');
  const languages = useLanguages();
  const dirty = useDirtyLanguage();

  const activeContractsExist = contracts?.some(contract => contract.status === STATUS_ACTIVE);

  // only disabled when event is expired and the current user is not a staff user
  let isDisabled = hasExpired && !unrestrictedAccess;

  // Restrict updating event dates to staff once the event has started
  const areDatesDisabled = hasStarted && !unrestrictedAccess;

  // Enable Event Details if it's a user credit event and there is an active contract available
  if (isUserCreditEvent && activeContractsExist) {
    isDisabled = false;
  }

  const {
    validateFields,
    getFieldValue,
    setFieldValue,
    setFieldsValue,
    setFields,
    getFieldsValue,
  } = form;

  const selectedEventDates = useWatch('eventDates', form);

  useEffect(() => {
    if (selectedEventDates && contracts && isUserCreditEvent) {
      const [startDate, endDate] = selectedEventDates;
      setBannerMessage(getBannerMessage(contracts, startDate, endDate));
    }
  }, [selectedEventDates, contracts, isUserCreditEvent]);

  const saveFn = async values => {
    try {
      const { data } = await updateFn(organizationId, eventId, {
        ...values,
        eventIconImageId: values.eventIconImageId?.id,
      });
      setEvent(data);

      // By default, warning modal for changing language should not be shown.
      // Therefore, after each save it is set back to false and the conditions
      // are use the newly saved values to determine whether or not to show the modal.
      dirty.reset();

      dispatch(setGlobalEvent(data));
      message.success(t`Successfully updated event.`);
    } catch (e) {
      const error = ERROR_MESSAGES_BY_CODE()[e.code] ?? t`Error updating event.`;
      message.error(error);
    } finally {
      setUpdating(false);
    }
  };

  // in case validateFields fail it shows the error alert
  const onFail = () => setShowErrorAlert(true);

  const onFinish = () => {
    setUpdating(true);
    setShowErrorAlert(false);

    const values = getFieldsValue();

    // remove `eventDates` from values as this shouldn't be sent to API
    // secondLanguageCheckbox is a view only field
    // customEmailLocal and customEmailDomain need to be combined as outboundEmailAddress
    const {
      eventDates,
      secondLanguageCheckbox,
      customEmailLocal,
      customEmailDomain,
      ...payload
    } = values;

    // prepend http when the provided URL doesn't have http or https
    // its for keeping backward compatibility with previous implementation
    if (
      values.eventWebsite !== null &&
      values.eventWebsite !== '' &&
      !values.eventWebsite?.startsWith('http://') &&
      !values.eventWebsite?.startsWith('https://')
    ) {
      payload.eventWebsite = `http://${payload.eventWebsite}`;

      // Updating the form with new value for eventWebsite
      setFieldsValue({
        eventWebsite: payload.eventWebsite,
      });
    }

    const [startDate, endDate] = values.eventDates;

    // Rewrite form dates to follow api format
    payload.startDate = startDate.format(FORMAT_DATE);
    payload.endDate = endDate.format(FORMAT_DATE);

    // If no custom email, set it to null
    if (!payload.useCustomOutboundEmailAddress) {
      payload.outboundEmailAddress = null;
      // Updating the form with new value for outboundEmailAddress
      setFieldsValue({ outboundEmailAddress: null, customEmailLocal: '', customEmailDomain: '' });
    } else if (isEmailDomainFFEnabled && customEmailLocal && customEmailDomain) {
      // If the FF is enabled and there is a custom email address in two parts, merge it together
      payload.outboundEmailAddress = `${customEmailLocal}@${customEmailDomain}`;
    }

    // If checkbox to add another language is not checked, clear the array
    if (!payload.alternativeLanguages) {
      payload.alternativeLanguages = [];
      setFieldsValue({ alternativeLanguages: [] });
    }

    if (dirty.value) {
      return Modal.confirm({
        title: t`Change Event Language?`,
        content: t`All Attendees will have their language settings reset to the primary event language.`,
        okText: t`Continue`,
        onOk() {
          return saveFn(payload);
        },
        onCancel() {
          setUpdating(false);
        },
      });
    }

    return saveFn(payload);
  };

  // Link for support article used on Event Support Email subtext
  const emailHelpLink = (
    <a
      key="supportLink"
      href="https://help.eventmobi.com/en/knowledge/what-is-the-event-support-email-in-the-event-info-section-needed-for"
      target="_blank"
      rel="noreferrer noopener"
    >{t`Help Desk`}</a>
  );

  const supportEmailLink = (
    <a
      key="supportEmail"
      href={`mailto:${SUPPORT_EMAIL}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {SUPPORT_EMAIL}
    </a>
  );

  let initialCustomEmailValue = { outboundEmailAddress: event?.outboundEmailAddress };
  if (isEmailDomainFFEnabled) {
    const [customEmailLocal = '', customEmailDomain = ''] =
      event?.outboundEmailAddress?.split('@') ?? [];
    initialCustomEmailValue = { customEmailLocal, customEmailDomain };
  }

  return (
    <Wrap>
      {hasExpired && (
        <Alert
          message={t`As your event has ended, further updates to its Event Details are no longer possible.`}
          type="info"
          style={{ margin: '30px 20px' }}
          showIcon
        />
      )}

      {showErrorAlert && (
        <Alert
          message={t`Please address the errors shown below`}
          type="error"
          style={{ margin: '30px 20px' }}
          showIcon
        />
      )}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFail}
        initialValues={{
          name: event?.name,
          shortcode: event?.shortcode,
          language: event?.language,
          alternativeLanguages:
            event?.alternativeLanguages?.length > 0 ? event?.alternativeLanguages[0] : '',
          description: event?.description,
          eventWebsite: event?.eventWebsite,
          useCustomOutboundEmailAddress: event?.useCustomOutboundEmailAddress ?? false,
          ...initialCustomEmailValue,
          supportEmailAddress: event?.supportEmailAddress,
          eventDates: [moment(event?.startDate), moment(event?.endDate)],
          timezone: event?.timezone,
          timeFormat: event?.timeFormat,
          locationName: event?.locationName,
          locationAddress: event?.locationAddress,
          secondLanguageCheckbox: showSecondaryLanguage,
          eventIconImageId: { id: event?.eventIconImageId },
        }}
      >
        <Field
          label={<Label isRequired>{t`Event Name`}</Label>}
          name="name"
          rules={[{ required: true, message: t`Event Name is required` }]}
        >
          <Input disabled={isDisabled} />
        </Field>
        <Field label={<Label isRequired>{t`Event Code`}</Label>}>
          <Field
            name="shortcode"
            rules={[{ required: true, message: t`Event Code is required` }]}
            noStyle
          >
            <Input disabled />
          </Field>
          <Description>
            {jt`Used to create a unique URL for your event space. To change your Event Code, contact ${supportEmailLink}`}
          </Description>
        </Field>
        <Field
          label={
            <Label isRequired>
              {event.multilingualEventapp ? t`Primary Event Language` : t`Event Language`}
            </Label>
          }
          name="language"
          dependencies={['alternativeLanguages']}
          rules={[
            {
              required: true,
              message: t`Please select a language.`,
            },
            {
              validator: async (rule, value) => {
                if (value !== getFieldValue('alternativeLanguages')) {
                  return;
                }
                throw new Error('error');
              },
              message: t`The primary and secondary event languages must be different.`,
            },
          ]}
        >
          <Select
            showSearch
            id="language"
            placeholder={isDisabled ? t`Unavailable` : t`Please select a language`}
            disabled={isDisabled}
            filterOption={searchOptionByLabel}
            onChange={() => dirty.onChange(showSecondaryLanguage)}
          >
            {languages.map(({ label, value }) => (
              <Option key={label} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Field>

        {event.multilingualEventapp && (
          <Field valuePropName="checked">
            <Checkbox
              disabled={isDisabled}
              checked={showSecondaryLanguage}
              onChange={value => {
                // This is required since selecting the same language for both primary and
                // secondary languages, and then unchecking the checkbox leaves a validation
                // error which prevents saving. Relying on the validator in 'onSubmit' does
                // not work because it takes the existing validation status, rather than
                // re-validating them. This is why we reset the errors.
                if (!value.target.checked) {
                  setFieldsValue({ alternativeLanguages: '' });
                  setFields([
                    {
                      language: {
                        value: getFieldValue('language'),
                        errors: [],
                      },
                    },
                  ]);
                  validateFields(['language']);
                }
                setShowSecondaryLanguage(value.target.checked);
                dirty.onChange(event.alternativeLanguages.length > 0 && !value.target.checked);
              }}
            >
              {t`Add another language\n`}
            </Checkbox>
            <br />
            <Description>
              {t`Provides attendees the option to change the language on the Event Space. All of your event content will need to be written in both languages.`}
            </Description>
          </Field>
        )}

        {event.multilingualEventapp && showSecondaryLanguage && (
          <Field
            label={<Label isRequired>{t`Secondary Event Language`}</Label>}
            name="alternativeLanguages"
            dependencies={['language']}
            rules={[
              {
                required: true,
                message: t`Please select a secondary event language.`,
              },
              {
                validator: async (rule, value) => {
                  if (value !== getFieldValue('language')) {
                    return;
                  }
                  throw new Error('error');
                },
                message: t`The primary and secondary event languages must be different.`,
              },
            ]}
          >
            <Select
              showSearch
              id="alternativeLanguages"
              placeholder={isDisabled ? t`Unavailable` : t`Please select a language`}
              disabled={isDisabled}
              filterOption={searchOptionByLabel}
              onChange={() => dirty.onChange(event.alternativeLanguages.length > 0)}
            >
              {languages.map(({ label, value }) => (
                <Option key={label} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Field>
        )}

        <Field>
          <RichTextEditor
            label={<Label>{t`Event Description`}</Label>}
            name="description"
            disabled={isDisabled}
            eventId={eventId}
            rules={[]}
          />
        </Field>

        <Field>
          <Url
            key="eventWebsite"
            label={t`Event Website`}
            name="eventWebsite"
            type={UrlTypes.Website}
            setFieldValue={setFieldValue}
            validateFields={validateFields}
            disabled={isDisabled}
          />
        </Field>
        <Field label={<Label>{t`Event Icon`}</Label>}>
          <Text>{t`This will show in the login pages and browser tab associated to your event.`}</Text>
          <Field noStyle name="eventIconImageId" trigger="onComplete">
            <ImageUpload
              enableCrop
              resourceType="events"
              resourceId={eventId}
              cropSettings={{ aspect: 1 }}
              width={100}
              height={100}
              information={t`We recommend image dimensions of at least 1024 x 1024 pixels.`}
            />
          </Field>
        </Field>
        <Header title={t`Email`} />
        <EmailField event={event} isDisabled={isDisabled} isStaff={unrestrictedAccess} />

        <Field label={<Label>{t`Event Support Email`}</Label>}>
          <Field
            noStyle
            name="supportEmailAddress"
            rules={[
              {
                type: 'email',
                message: t`Invalid email address.`,
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </Field>
          <Description>
            {jt`If the ${emailHelpLink} is enabled, any emails sent through it will be directed to this email.`}
          </Description>
        </Field>
        <Header title={t`Event Dates`} />
        {/* Styles for the RangePicker can't go above or below it because Form.Item
         must have only one component as children. This is the closest I can get to it. */}
        {unrestrictedAccess && eventMaxDurationConfig ? (
          <SupportPickerStyles />
        ) : (
          <PickerPopupStyles />
        )}
        <Field label={<Label isRequired>{t`Event Dates`}</Label>}>
          {!unrestrictedAccess && (
            <Typography.Text type="secondary">
              {t`Dates cannot be updated once the event has started.`}
            </Typography.Text>
          )}
          <RangePickerField
            name="eventDates"
            rules={[
              { type: 'array', required: true, message: t`Both Event dates are required.` },
              {
                validator: (_, value) => {
                  if (!eventMaxDurationConfig || !value || !value[0] || !value[1]) {
                    return Promise.resolve();
                  }
                  if (value[1].diff(value[0], 'days') > eventMaxDurationConfig) {
                    return Promise.reject(
                      new Error(t`Event duration should be less than max duration config.`)
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            extra={
              isUserCreditEvent &&
              bannerMessage && (
                <Alert
                  style={{ marginTop: '8px' }}
                  type="warning"
                  showIcon
                  message={bannerMessage}
                />
              )
            }
          >
            <RangePicker
              disabled={areDatesDisabled}
              showTime={false}
              onCalendarChange={dates => {
                setFieldsValue({ eventDates: dates });
              }}
              disabledDate={current => {
                const dates = getFieldValue('eventDates');
                if (!dates || dates.length === 0 || !eventMaxDurationConfig) {
                  if (isUserCreditEvent && !unrestrictedAccess) {
                    // Restrict past days for organizers for user credit events
                    return current && current.diff(moment(), 'days') < 0;
                  }
                  return false;
                }
                const tooLate = dates[0] && current.diff(dates[0], 'days') > eventMaxDurationConfig;
                const tooEarly =
                  dates[1] && dates[1].diff(current, 'days') > eventMaxDurationConfig;
                return tooEarly || tooLate;
              }}
              format={LOCALE_DATE}
              separator="→"
            />
          </RangePickerField>
        </Field>

        <Field label={<Label>{t`Event Timezone`}</Label>} name="timezone">
          <Select
            showSearch
            placeholder={isDisabled ? t`Unavailable` : t`Please select a timezone`}
            disabled={isDisabled}
            filterOption={searchOptionByLabel}
          >
            {Object.keys(TIMEZONES).map(timezone => (
              <Select.OptGroup key={timezone}>
                {TIMEZONES[timezone].map(({ label, value }) => (
                  <Option key={label} value={value}>
                    <Label disabled={isDisabled}>{label}</Label>
                  </Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </Field>
        <Field label={<Label>{t`Time Format`}</Label>} name="timeFormat">
          <Radio.Group disabled={isDisabled}>
            <Radio.Button value="12 Hour">{t`12 Hour`}</Radio.Button>
            <Radio.Button value="24 Hour">{t`24 Hour`}</Radio.Button>
          </Radio.Group>
        </Field>
        <Header title={t`Event Location`} />
        <Field label={<Label>{t`Event Location Name`}</Label>} name="locationName">
          <Input disabled={isDisabled} />
        </Field>
        <Field label={<Label>{t`Event Location Address`}</Label>} name="locationAddress">
          <GoogleMap disabled={isDisabled} />
        </Field>
        <StickyFooter>
          <Button type="primary" htmlType="submit" loading={isUpdating} disabled={isUpdating}>
            {isUpdating ? t`Saving Event Information` : t`Save Event Information`}
          </Button>
        </StickyFooter>
      </Form>
    </Wrap>
  );
};

export default EventInfo;
