import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

import useStableLocation from 'common/hooks/useStableLocation';
import { isEnabled as isFlagEnabled } from 'common/state/flags';

import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

import {
  OLD_TYPES,
  MARGIN,
  PADDING,
  COLUMN_SIZE,
  UNIT_DIVISION,
  TYPE_IMAGE,
  TYPE_PERSONAL_CODE,
  MINIMUM_DIMENSIONS,
  GRAY_BORDER_COLOR,
  RED_BORDER_COLOR,
  BLEED_GAP,
  GRAY_BACKGROUND_COLOR,
  SIMPLE_LABEL_GAP,
  GREEN_BORDER_COLOR,
  WHITE_BACKGROUND_COLOR,
  BADGE_PREVIEW_BOX_SHADOW,
} from './constants';

const Style = createGlobalStyle`
  .react-grid-item.react-grid-placeholder {
    && {
      background: #000000;
      opacity: 0.2;
      border-radius: 2px;
    }
  }

  .react-draggable-dragging {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNOCAxSDdWMlY2LjVIMkgxVjcuNVYxMC41VjExLjVIMkgxMUgxMlYxMC41VjJWMUgxMUg4WiIgZmlsbD0iIzU5NTk1OSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=);
    background-position: bottom right;
    padding: 5px;

    &&::after {
      border: none;
    }
  }
`;

const SimpleLabelWrapper = styled.div`
  padding: ${SIMPLE_LABEL_GAP.HORIZONTAL * COLUMN_SIZE}px
    ${SIMPLE_LABEL_GAP.VERTICAL * COLUMN_SIZE}px;
  background-color: ${WHITE_BACKGROUND_COLOR};
  border: solid ${props => (props.showBorder ? '1px' : 0)} ${GRAY_BORDER_COLOR};
  box-shadow: ${props => (props.showBoxShadow ? BADGE_PREVIEW_BOX_SHADOW : 'none')};
`;

const Wrap = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  background-color: ${props => props.color};
  background-image: url('${props => props.image?.url ?? ''}');
  background-attachment: initial;
  background-size: ${({ size }) => size};

  position: relative;

  // Putting the border in a pseudo-element lets it overlay the content, rather
  // than affecting the layout + available space.
  &:after {
    content: '';
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: solid ${props => (props.showBorder ? '1px' : 0)} ${props => props.borderColor};
    box-shadow: ${props => (props.showBoxShadow ? BADGE_PREVIEW_BOX_SHADOW : 'none')};
  }
`;

const NonIntractableBadgeBorder = styled.div`
  border: 1px solid ${GRAY_BORDER_COLOR};
  z-index: 10; // Keeping the z-index is higher than other elements so the border is always on top
  pointer-events: none; // Keeping the border non-interactive
  box-shadow: ${props => (props.trimBleedArea ? props.boxShadowProperties : 'none')};
`;

const Grid = ({
  children,
  widgets = [],
  background = {},
  showBorder = false,
  size,
  onChange = () => {},
  readonly = false,
  isBleedEnabled = false,
  isPreviewMode = false,
  isSimpleLabelBadge = false,
}) => {
  const isOnsiteBadgePrintingEnabled = useSelector(st =>
    isFlagEnabled(st, 'onsite_badge_printing')
  );

  const location = useStableLocation();
  const isExportingBadges = location.pathname.includes('/pdf-capture/badges/');

  const { width, height, cols, rows: maxRows, unit, innerBadgeSize } = size;

  const badgeWidth = innerBadgeSize.width * UNIT_DIVISION[unit];
  const badgeHeight = innerBadgeSize.height * UNIT_DIVISION[unit];

  const containerWidth = width * UNIT_DIVISION[unit] * COLUMN_SIZE;
  const containerHeight = height * UNIT_DIVISION[unit] * COLUMN_SIZE;

  const source = useMemo(() => {
    return widgets.map(({ id, layout }) => ({
      ...layout,
      i: id,
      isDraggable: !readonly,
      isResizable: !readonly,
    }));
  }, [widgets, readonly]);

  // resize with preserving aspect ratio
  const handleResize = useCallback(
    (layout, oldItem, newItem) => {
      const widget = widgets.find(({ id }) => id === newItem.i);
      if (!widget) return;

      const { type } = widget;

      /* eslint-disable no-param-reassign */
      if (MINIMUM_DIMENSIONS[type]) {
        const { w: minW, h: minH } = {
          w: MINIMUM_DIMENSIONS[type][unit].w * UNIT_DIVISION[unit],
          h: MINIMUM_DIMENSIONS[type][unit].h * UNIT_DIVISION[unit],
        };
        newItem.w = Math.max(newItem.w, minW);
        newItem.h = Math.max(newItem.h, minH);
      }

      // clip the width and height so that it doesn't go beyond badge size
      const [maxW, maxH] = [
        Math.min(cols, newItem.x + newItem.w) - newItem.x,
        Math.min(maxRows, newItem.y + newItem.h) - newItem.y,
      ];

      if (type === TYPE_IMAGE) {
        // now that maxW/maxH might not be the same ratio, figure out the best way to size the widget
        const originalRatio = oldItem.w / oldItem.h;
        if (maxW / maxH > originalRatio) {
          newItem.h = maxH;
          newItem.w = newItem.h * originalRatio;
        } else {
          newItem.w = maxW;
          newItem.h = newItem.w / originalRatio;
        }
      } else if (type === TYPE_PERSONAL_CODE) {
        const minSide = Math.min(newItem.w, newItem.h);
        newItem.w = minSide;
        newItem.h = minSide;
      }
      /* eslint-enable no-param-reassign */
    },
    [cols, maxRows, unit, widgets]
  );

  let wrapBorderColor = isBleedEnabled ? RED_BORDER_COLOR : GRAY_BORDER_COLOR;

  if (isSimpleLabelBadge) {
    wrapBorderColor = GREEN_BORDER_COLOR;
  }

  // gap between the badges and bleed
  const bleedAndBorderGap = (containerWidth - badgeWidth * COLUMN_SIZE) / 2;
  // during export, show the border only if `showBorder` is true
  const showNonIntractableBorderInExport = !isExportingBadges || (isExportingBadges && showBorder);
  // Always render NonIntractableBadgeBorder if `bleed` is enabled unless `showBorder` is false while exporting
  const renderNonIntractableBorder = isBleedEnabled && showNonIntractableBorderInExport;

  const trimBleedArea = isPreviewMode && isBleedEnabled;

  const hideLabelInnerBorder = isSimpleLabelBadge && (isPreviewMode || isExportingBadges);
  const showWrapBorder = !isExportingBadges && !trimBleedArea && !hideLabelInnerBorder;
  const exportWrapBorder =
    isExportingBadges && !isBleedEnabled && !hideLabelInnerBorder && showBorder;

  const wrappedGrid = (
    <Wrap
      {...background}
      readonly={readonly}
      width={containerWidth}
      height={containerHeight}
      borderColor={wrapBorderColor}
      showBorder={showWrapBorder || exportWrapBorder}
      showBoxShadow={
        // In case of bleed enabled badges, box-shadow is applied to NonIntractableBadgeBorder
        // In case of simple_label badges, box-shadow is applied to SimpleLabelWrapper
        isOnsiteBadgePrintingEnabled && isPreviewMode && !isBleedEnabled && !isSimpleLabelBadge
      }
    >
      <Style />
      <ReactGridLayout
        allowOverlap
        // adding `preventCollision` fixes the issue where dragging one widget would
        // cause other overlapping widgets to move a little
        preventCollision
        maxRows={maxRows}
        width={containerWidth}
        cols={cols}
        rowHeight={containerWidth / cols}
        margin={MARGIN}
        containerPadding={PADDING}
        isDraggable={!readonly}
        isResizable={!readonly}
        // onDragStop and onResizeStop are here as workaround until the following issue is fixed in react-grid-layout
        // https://github.com/react-grid-layout/react-grid-layout/issues/1775
        // When fixed, we should use `onLayoutChange={layout => onChange(layout)}` instead
        onDragStop={layout => onChange(layout)}
        onResizeStop={(layout, oldItem, newItem) => {
          handleResize(layout, oldItem, newItem);
          onChange(layout);
        }}
        layout={source}
        readonly={readonly}
      >
        {renderNonIntractableBorder && (
          <NonIntractableBadgeBorder
            key="non-intractable-border"
            data-grid={{
              x: BLEED_GAP[unit],
              y: BLEED_GAP[unit],
              w: badgeWidth,
              h: badgeHeight,
              static: true,
            }}
            // only trim bleed when in preview
            trimBleedArea={trimBleedArea}
            boxShadowProperties={
              isOnsiteBadgePrintingEnabled
                ? `${BADGE_PREVIEW_BOX_SHADOW}, 0 0 0 ${bleedAndBorderGap}px ${GRAY_BACKGROUND_COLOR}`
                : `0 0 0 ${bleedAndBorderGap}px ${WHITE_BACKGROUND_COLOR}`
            }
          />
        )}
        {children}
      </ReactGridLayout>
    </Wrap>
  );

  return isSimpleLabelBadge ? (
    <SimpleLabelWrapper showBoxShadow={isPreviewMode} showBorder={showBorder}>
      {wrappedGrid}
    </SimpleLabelWrapper>
  ) : (
    wrappedGrid
  );
};

Grid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  background: PropTypes.shape({
    color: PropTypes.string,
    image: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  showBorder: PropTypes.bool,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number,
      }),
      id: PropTypes.string,
      type: PropTypes.oneOf(Object.keys(OLD_TYPES())),
    })
  ).isRequired,
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    innerBadgeSize: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
  showBleed: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isBleedEnabled: PropTypes.bool,
  isSimpleLabelBadge: PropTypes.bool,
};

export default Grid;
