import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Table as AntTable, Modal as AntModal, Space, Divider, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { t, ngettext, msgid } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import ShowMore from 'common/components/ShowMore';
import { PAGE_SIZE } from 'common/hooks/useTable';
import { ISO_LOCALE_MAP, LANGUAGE_BY_ISO } from 'common/services/translation/api';

import SearchFilter, { SearchFilterIcon } from '@components/SearchFilter';
import usePermission, { DELETE_ORGANIZERS, UPDATE_ORGANIZERS } from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/sessions.svg';

import { ROLE_LABEL } from './constants';
import {
  ADMIN_ID,
  COLLABORATOR_ID,
  remove as removeFn,
  resendInvite as resendInviteFn,
} from './service';

const { Column } = AntTable;

const StyledTable = styled(AntTable)`
  // Antd doesn't let us add anything to the pagination row, so we add a footer
  // and allow it to overlap
  .ant-table-footer {
    background: none;
    float: left;

    // Match the pagination controls so the text lines up
    line-height: 32px;
    padding: 0;
    margin: 16px 0;
  }
`;

const Footer = () => (
  <Typography.Text type="secondary">
    {t`Expired invitations will automatically be removed from the table.`}
  </Typography.Text>
);

const Table = ({
  dataSource,
  events,
  loading,
  onRowSelection,
  selectedRowKeys,
  onResend,
  onRemove,
  onEdit,
}) => {
  const { authorized, authorizedAny } = usePermission();

  const rowSelection = {
    columnWidth: 50,
    selectedRowKeys,
    onChange: onRowSelection,
  };

  const sorter = (row1, row2, fieldName) => {
    return row1[fieldName]?.localeCompare(row2[fieldName]);
  };

  const onFilter = (value, record, fieldName) =>
    record[fieldName]?.toString().toLowerCase().includes(value?.toLowerCase());

  if (!loading && dataSource.length === 0) {
    return (
      <Empty
        image={EmptyImage}
        title={t`No Pending Organizers`}
        body={t`Organizers that are invited but have not yet accepted their invitation will appear here.`}
      />
    );
  }

  return (
    <StyledTable
      rowKey="id"
      pagination={{
        showTotal: (total, [from, to]) => t`${from}-${to} of ${total} items`,
        showSizeChanger: false,
        pageSize: PAGE_SIZE,
      }}
      footer={Footer}
      dataSource={dataSource}
      tableLayout="auto"
      loading={loading}
      rowSelection={rowSelection}
    >
      <Column
        title={t`Email`}
        dataIndex="email"
        sorter={(a, b) => sorter(a, b, 'email')}
        defaultSortOrder="ascend"
        filterDropdown={SearchFilter}
        filterIcon={SearchFilterIcon}
        onFilter={(value, record) => onFilter(value, record, 'email')}
      />
      <Column
        title={t`Role`}
        width={200}
        dataIndex="roleId"
        filters={[ADMIN_ID, COLLABORATOR_ID].map(roleId => ({
          text: ROLE_LABEL()[roleId],
          value: roleId,
        }))}
        onFilter={(value, record) => onFilter(value, record, 'roleId')}
        render={roleId => ROLE_LABEL()[roleId]}
      />
      <Column
        title={t`Event Access`}
        width={200}
        dataIndex="eventIds"
        filters={[{ text: t`All Events`, value: 'all' }].concat(
          events.map(({ shortcode, id }) => ({ text: shortcode, value: id }))
        )}
        onFilter={(value, record) => record.eventIds?.includes(value)}
        render={(eventIds, record) => {
          if (!eventIds || eventIds[0] === 'all' || record.roleId === ADMIN_ID) {
            return t`All Events`;
          }

          const organizerEvents = events
            .filter(({ id }) => eventIds.includes(id))
            .map(({ shortcode, id }) => ({ name: shortcode, id }));

          return <ShowMore items={organizerEvents} isTag />;
        }}
      />
      <Column
        title={t`Language`}
        width={200}
        dataIndex="language"
        filters={Object.keys(ISO_LOCALE_MAP).map(language => ({
          text: LANGUAGE_BY_ISO(language),
          value: language,
        }))}
        onFilter={(value, record) => onFilter(value, record, 'language')}
        render={value => LANGUAGE_BY_ISO(value)}
      />
      <Column
        title={t`Expiry`}
        width={200}
        dataIndex="inviteExpiryDatetime"
        sorter={(a, b) => sorter(a, b, 'inviteExpiryDatetime')}
        render={value => moment(value).format('ll')}
      />
      {authorizedAny(UPDATE_ORGANIZERS, DELETE_ORGANIZERS) && (
        <Column
          title={t`Action`}
          width={400}
          render={(_, record) => (
            <Space size="small" split={<Divider type="vertical" />}>
              {authorized(UPDATE_ORGANIZERS) && (
                <Typography.Link onClick={() => onEdit(record)}>{t`Edit`}</Typography.Link>
              )}
              {authorized(UPDATE_ORGANIZERS) && (
                <Typography.Link
                  onClick={() => onResend(record)}
                >{t`Send New Invite`}</Typography.Link>
              )}
              {authorized(DELETE_ORGANIZERS) && (
                <Typography.Link type="danger" onClick={() => onRemove(record?.id)}>
                  {t`Remove`}
                </Typography.Link>
              )}
            </Space>
          )}
        />
      )}
    </StyledTable>
  );
};

Table.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
  onRowSelection: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

const Pending = ({ organizationId, events, list, loading, onResend, onRemove, onEdit }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleResend = invite => {
    AntModal.confirm({
      title: t`Send new invite to ${invite.email}?`,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      content: t`Are you sure you want to send new invite to the recipient?`,
      async onOk() {
        await resendInviteFn(organizationId, invite.id);
        onResend();
      },
    });
  };

  const handleRemove = keys => {
    const { email } = list.find(organizer => organizer.id === keys[0]);
    const title = ngettext(
      msgid`Remove invite to ${email}?`,
      `Remove invite to ${keys.length} users?`,
      keys.length
    );
    const okText = ngettext(msgid`Yes, Remove Invite`, `Yes, Remove Invites`, keys.length);
    const content = ngettext(
      msgid`This will instantly remove and expire the invitation link sent to the recipient.`,
      `This will instantly remove and expire the invitation link sent to the recipients.`,
      keys.length
    );

    AntModal.confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText,
      cancelText: t`No, Cancel`,
      content,
      async onOk() {
        await Promise.all(keys.map(key => removeFn(organizationId, key)));
        onRemove();
        setSelectedRows(selectedRows.filter(v => !keys.includes(v)));
      },
    });
  };

  return (
    <>
      <Table
        dataSource={list}
        events={events}
        loading={loading}
        onRowSelection={keys => setSelectedRows([...keys])}
        selectedRowKeys={selectedRows}
        onResend={handleResend}
        onRemove={key => handleRemove([key])}
        onEdit={onEdit}
      />

      <DeleteBar
        style={{ left: 0 }}
        open={selectedRows.length > 0}
        buttonText={ngettext(
          msgid`Cancel ${selectedRows.length} Invite`,
          `Cancel ${selectedRows.length} Invites`,
          selectedRows.length
        )}
        onClick={() => handleRemove(selectedRows)}
      />
    </>
  );
};

Pending.propTypes = {
  organizationId: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(Object).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  onResend: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
};

export default Pending;
