import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row as AntRow } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { jt, msgid, ngettext, t } from 'ttag';

import DeleteBar from 'common/components/DeleteBar';
import Empty from 'common/components/Empty';
import DeleteModal from 'common/components/Modal/DeleteModal';
import { getId } from 'common/state/organization';

import usePermission, { CREATE_CUSTOM_DOMAINS, DELETE_CUSTOM_DOMAINS } from '@hooks/usePermission';
import EmptyImage from '@images/illustrations/custom-domain.svg';

import Modal from './Modal';
import { useRemoveCustomDomainMutation, useList } from './queries';
import Table from './Table';

const Row = styled(AntRow)`
  margin: 20px 0;
`;

const CustomDomain = () => {
  const { authorized } = usePermission();
  const organizationId = useSelector(getId);
  const { data: dataSource, isFetching: loading } = useList(organizationId);
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync: removeFn } = useRemoveCustomDomainMutation();
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleDelete = records => {
    const { domainName } = records[0];

    DeleteModal.confirm({
      label: {
        singular: t`Custom Domain`,
        plural: t`Custom Domains`,
      },
      itemName: domainName,
      additionalInfo: ngettext(
        msgid`Events using this Custom Domain will show an error page.`,
        `Events using these Custom Domains will show an error page.`,
        records.length
      ),
      count: records.length,
      async onDelete() {
        await removeFn({ organizationId, ids: records.map(({ id }) => id) });
        setSelectedRows([]);
      },
    });
  };

  const link = (
    <a
      key="em"
      href="https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >
      {t`Click here to learn more.`}
    </a>
  );

  const isEmpty = !loading && !(dataSource?.length > 0);

  return (
    <>
      {showModal && <Modal organizationId={organizationId} onClose={() => setShowModal(false)} />}
      <Row>
        <Col>
          {authorized(CREATE_CUSTOM_DOMAINS) && (
            <Button
              key="add"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
            >
              {t`Add Custom Domain`}
            </Button>
          )}
        </Col>
      </Row>
      {isEmpty && (
        <Empty
          image={EmptyImage}
          title={t`Add a Custom Domain`}
          body={
            <>
              {t`Connect a custom domain to provide a consistent online brand identity.`}
              <br />
              {jt`Need help with your domain? ${link}`}
            </>
          }
        />
      )}

      {!isEmpty && (
        <Table
          dataSource={dataSource}
          loading={loading}
          onRowSelection={(_, rows) => setSelectedRows(rows)}
          selectedRowKeys={selectedRows.map(({ id }) => id)}
          onViewRecords={() => {}}
          onDelete={handleDelete}
        />
      )}

      {authorized(DELETE_CUSTOM_DOMAINS) && (
        <DeleteBar
          style={{ left: 0 }}
          open={selectedRows.length > 0}
          buttonText={ngettext(
            msgid`Delete ${selectedRows.length} Custom Domain`,
            `Delete ${selectedRows.length} Custom Domains`,
            selectedRows.length
          )}
          onClick={() => handleDelete(selectedRows)}
        />
      )}
    </>
  );
};
export default CustomDomain;
