import { Space, Radio, Select, Typography, Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { jt, t } from 'ttag';

import Emphasis from 'common/components/Emphasis';
import NotFoundContent from 'common/components/NotFoundContent';
import { SALES_EMAIL } from 'common/services/support/types';

import { LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT } from '@domains/Organization/service';

import { searchEvents as searchEventsFn } from './credit-service';

export const SOURCE_BLANK = 'blank';
export const SOURCE_DUPLICATE = 'duplicate';

const Content = ({
  currentMode,
  hasContract,
  source,
  sourceEvent,
  setSourceEvent,
  setSource,
  orgId,
}) => {
  const [events, setEvents] = useState([]);

  const handleEventSearch = async search => {
    if (search === '') {
      return setEvents([]);
    }
    try {
      const res = await searchEventsFn(search, orgId);
      const e = res.map(({ id, name, shortcode: code }) => ({
        id,
        code,
        name,
      }));

      return setEvents(e);
    } catch {
      throw new Error('Error searching events');
    }
  };

  const handleEventSelect = option => {
    setSourceEvent(events.filter(event => event.id === option.value)?.[0]);
  };

  const handleSourceChange = e => {
    const src = e.target?.value;
    setSource(src);
    if (src === SOURCE_BLANK && sourceEvent?.id) {
      setSourceEvent(null);
    }
  };

  if (currentMode === LICENSE_TYPE_USER_CREDIT && !hasContract) {
    const contactUs = (
      <a key="contact-us" href={`mailto:${SALES_EMAIL}`} target="_blank" rel="noreferrer noopener">
        {t`contact us`}
      </a>
    );
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Title level={5}>{t`Content`}</Typography.Title>
        <Alert
          showIcon
          type="error"
          message={t`No Active Contract`}
          description={jt`Your organization is under User Credit License, but there is no active contract. Please ${contactUs} to resolve this.`}
        />
      </Space>
    );
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Radio.Group onChange={handleSourceChange} value={source}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Radio value={SOURCE_BLANK}>
            {t`Blank`}
            <br />
            <Emphasis>{t`Start with a new empty event`}</Emphasis>
          </Radio>
          <Radio value={SOURCE_DUPLICATE}>
            {t`Duplicate Previous Event`}
            <br />
            <Emphasis>{t`Start with a previous event design and library set up`}</Emphasis>
          </Radio>
        </Space>
      </Radio.Group>
      {source === SOURCE_DUPLICATE && (
        <Select
          style={{ width: 400, marginLeft: 25 }}
          value={
            sourceEvent && {
              value: sourceEvent.id,
              label: `${sourceEvent.name} (${sourceEvent.code})`,
            }
          }
          showSearch
          placeholder={t`Search for an event to duplicate`}
          onSearch={handleEventSearch}
          options={events.map(event => ({
            value: event.id,
            label: `${event.name} (${event.code})`,
          }))}
          filterOption={false}
          labelInValue
          onSelect={handleEventSelect}
          notFoundContent={<NotFoundContent />}
          onDropdownVisibleChange={open => {
            if (!open) setEvents([]);
          }}
          dropdownRender={menu => {
            if (menu?.props?.values?.size > 0 && menu?.props?.searchValue === '') {
              return <NotFoundContent description={t`Please type to search`} />;
            }
            return <>{menu}</>;
          }}
        />
      )}
    </Space>
  );
};

Content.propTypes = {
  currentMode: PropTypes.oneOf([LICENSE_TYPE_EVENT_CREDIT, LICENSE_TYPE_USER_CREDIT]).isRequired,
  hasContract: PropTypes.bool.isRequired,
  source: PropTypes.string,
  sourceEvent: PropTypes.shape({}),
  setSourceEvent: PropTypes.func,
  setSource: PropTypes.func,
  orgId: PropTypes.string.isRequired,
};

export default Content;
