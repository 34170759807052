import { Form, Typography } from 'antd';
import styled from 'styled-components';

export const Field = styled(Form.Item)`
  && {
    max-width: 716px;
    padding: ${props => props.$padding || '0 32px'};
    margin-bottom: ${props => props.$marginBottom || '15px'};
  }
`;

export const Description = styled(Typography.Text).attrs(() => ({ type: 'secondary' }))`
  font-size: 0.8rem;
  font-style: italic;
`;
