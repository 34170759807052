import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { getCmsEvent, updateCmsEvent } from 'common/services/events/api';

const eventInfoOptions = (organizationId, eventId) => ({
  queryKey: ['orgId', organizationId, 'event', eventId, 'info'],
  queryFn: () => getCmsEvent(organizationId, eventId),
  select: ({ data }) => data,
  keepPreviousData: true,
});

export const useEventInfo = () => {
  const { organizationId, eventId } = useParams();

  return useQuery(eventInfoOptions(organizationId, eventId));
};

export const useEventInfoMutation = () => {
  const { organizationId, eventId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['orgId', organizationId, 'event', eventId, 'info', 'update'],
    mutationFn: payload => updateCmsEvent(organizationId, eventId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: eventInfoOptions(eventId).queryKey });
    },
  });
};
