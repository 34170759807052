import { Progress, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import useTranslation from 'common/hooks/useTranslation';

import {
  LIVESTREAM_PLAYBACK_HOURS,
  LIVESTREAM_BROADCAST_HOURS,
  VIDEO_STORAGE_HOURS,
  VIDEO_PLAYBACK_HOURS,
  PEOPLE_CREDITS,
} from '@services/data-usage-service';

import { STATUS_EXPIRED } from '../contracts-service';

import { LEVEL_ERROR, LEVEL_INFO, LEVEL_WARN } from './constants';

const formatHours = (seconds, locale) => {
  const rounded = Math.floor((100 * seconds) / 3600) / 100;

  return rounded.toLocaleString(locale);
};
const formatDates = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.year() === end.year()) {
    // Same year
    return `${start.format('MMM DD')} - ${end.format('MMM DD, YYYY')}`;
  }
  // Different years
  return `${start.format('MMM DD, YYYY')} - ${end.format('MMM DD, YYYY')}`;
};

const contractTag = status => {
  if (status === 'active') {
    return <Tag color="success">{t`Active`}</Tag>;
  }

  if (status === 'scheduled') {
    return <Tag color="warning">{t`Upcoming`}</Tag>;
  }

  return null;
};

const INFO = () => {
  const info = {
    [LIVESTREAM_BROADCAST_HOURS]: {
      titleFn: () => t`Streaming Hours`,
      descriptionFn: () => t`Number of hours broadcasted.`,
      unit: t`hours used`,
      hasDelay: true,
      format: formatHours,
    },
    [LIVESTREAM_PLAYBACK_HOURS]: {
      titleFn: () => t`Live Viewer Hours`,
      descriptionFn: () => t`Total time spent by attendees watching live stream content.`,
      unit: t`viewer hours used`,
      hasDelay: true,
      format: formatHours,
    },
    [VIDEO_STORAGE_HOURS]: {
      titleFn: () => t`Video Hours`,
      descriptionFn: () => t`Total duration of video content hosted in events' video lists.`,
      unit: t`hours used`,
      hasDelay: false,
      format: formatHours,
    },
    [VIDEO_PLAYBACK_HOURS]: {
      titleFn: () => t`On-Demand Viewer Hours`,
      descriptionFn: () => t`Total time spent by attendees watching on-demand content.`,
      unit: t`viewer hours used`,
      hasDelay: true,
      format: formatHours,
    },
    [PEOPLE_CREDITS]: {
      titleFn: entity => (
        <>
          {formatDates(entity.startDate, entity.endDate)} {contractTag(entity.status)}{' '}
        </>
      ),
      unit: t`People Credit(s)`,
      hasDelay: false,
      format: (value, locale) => value?.toLocaleString(locale),
    },
  };

  return info;
};

const COLORS = {
  [LEVEL_INFO]: {
    foreground: '#1890FF',
    background: '#1890FF',
  },
  [LEVEL_WARN]: {
    foreground: '#AD4E00',
    background: '#FA8C16',
  },
  [LEVEL_ERROR]: {
    foreground: '#F5222D',
    background: '#F5222D',
  },
};

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSpace = styled(Space)`
  margin: auto 0;
`;

const Title = styled(Typography.Text)`
  font-size: 16px;
`;

const ExtraSpace = styled(Space)`
  width: 500px;
  text-align: right;
  justify-content: flex-end;
`;

const UsageText = styled(Typography.Text)`
  font-size: 12px;
`;

const UsedText = styled(Typography.Text)`
  color: ${props => props.$color};
  font-size: 16px;
`;

const UsageLimitHeader = ({ type, used, limit, percent, level, disabled = false, ...entity }) => {
  const { locale } = useTranslation();

  const showCompactExpiredUsage = type === PEOPLE_CREDITS && entity.status === STATUS_EXPIRED;

  const { titleFn, descriptionFn, unit, hasDelay, format } = INFO()[type];

  let percentText = t`${percent}% used`;
  if (percent > 100 && type !== VIDEO_PLAYBACK_HOURS) {
    percentText = t`Overlimit`;
  }

  return (
    <Wrap>
      <StyledSpace size={0} direction="vertical">
        <Title disabled={disabled}>
          {titleFn(entity)}
          {hasDelay && (
            <Typography.Text type="secondary" disabled={disabled}>
              *
            </Typography.Text>
          )}
        </Title>
        <Typography.Text type="secondary" disabled={disabled}>
          {descriptionFn && descriptionFn(entity)}
        </Typography.Text>
      </StyledSpace>

      {showCompactExpiredUsage ? (
        <ExtraSpace size="middle" direction="horizontal">
          <UsageText type="secondary" disabled={disabled}>
            <UsedText
              $color={disabled ? '#00000040' : COLORS[level].foreground}
              disabled={disabled}
            >
              {format(used, locale)}
            </UsedText>{' '}
            {t`People Credit(s) used`}
          </UsageText>
        </ExtraSpace>
      ) : (
        <ExtraSpace size="middle" direction="horizontal">
          <UsageText type="secondary" disabled={disabled}>
            <UsedText
              $color={disabled ? '#00000040' : COLORS[level].foreground}
              disabled={disabled}
            >
              {format(used, locale)}
            </UsedText>{' '}
            / {format(limit, locale)}
            <br />
            {unit}
          </UsageText>
          <div style={{ width: 250 }}>
            <Progress percent={percent} showInfo={false} strokeColor={COLORS[level].background} />
          </div>
          <div style={{ width: 80, textAlign: 'left' }}>
            <Typography.Text type="secondary" disabled={disabled}>
              {percentText}
            </Typography.Text>
          </div>
        </ExtraSpace>
      )}
    </Wrap>
  );
};

UsageLimitHeader.propTypes = {
  type: PropTypes.oneOf([
    LIVESTREAM_BROADCAST_HOURS,
    LIVESTREAM_PLAYBACK_HOURS,
    VIDEO_STORAGE_HOURS,
    VIDEO_PLAYBACK_HOURS,
    PEOPLE_CREDITS,
  ]).isRequired,
  used: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  level: PropTypes.oneOf([LEVEL_INFO, LEVEL_WARN, LEVEL_ERROR]).isRequired,
  entity: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default UsageLimitHeader;
