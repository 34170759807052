import { Alert, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { jt, t } from 'ttag';

import Header from 'common/components/Header';
import useTranslation from 'common/hooks/useTranslation';
import { SUPPORT_EMAIL } from 'common/services/support/types';

import { PEOPLE_CREDITS } from '@services/data-usage-service';

import UsageLimitCollapse from '../UsageLimitCollapse';

const Section = styled.div`
  padding: 0px 45px;
`;

const Item = styled.div`
  padding: 8px 0px;
`;

const LEARN_MORE_EN = 'https://help.eventmobi.com/en/knowledge/user-credit-license';
const LEARN_MORE_DE = 'https://help.eventmobi.com/de/knowledge/unlimited-events-paket';

const PeopleCredits = ({ contracts }) => {
  const { locale } = useTranslation();

  const activeContracts = contracts.filter(contract => contract.status === 'active');
  const upcomingContracts = contracts
    .filter(contract => contract.status === 'scheduled')
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  const expiredContracts = contracts.filter(contract => contract.status === 'expired');
  const contactUsLink = (
    <a key="contact-us" href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noreferrer noopener">
      {t`contact our sales team`}
    </a>
  );

  const peopleCredit = (
    <Typography.Text strong type="secondary" key="people-credit-text">
      {t`People Credit`}
    </Typography.Text>
  );
  const learnMoreUrl = /de/.test(locale) ? LEARN_MORE_DE : LEARN_MORE_EN;
  const learnMore = (
    <a key="contact-us" href={learnMoreUrl} target="_blank" rel="noreferrer noopener">
      {t`Learn more.`}
    </a>
  );

  const noAvailableContracts = activeContracts.length === 0 && upcomingContracts.length === 0;

  return (
    <>
      <Header title={t`Available Contract(s)`} padding="10px 45px" />
      <Section>
        {!noAvailableContracts && (
          <Typography.Text type="secondary">{jt`One individual will use only one ${peopleCredit}, regardless of how many events they attend within a contract period. ${learnMore}`}</Typography.Text>
        )}
        {activeContracts.map(contract => (
          <Item key={contract.id}>
            <UsageLimitCollapse type={PEOPLE_CREDITS} {...contract} />
          </Item>
        ))}

        {upcomingContracts.map(contract => (
          <Item key={contract.id}>
            <UsageLimitCollapse type={PEOPLE_CREDITS} disabled {...contract} />
          </Item>
        ))}

        {noAvailableContracts && (
          <Item>
            <Alert
              message={jt`There is no active or upcoming contract. Please ${contactUsLink} to add a contract.`}
              type="warning"
              style={{ marginBottom: '24px', width: '100%', border: 0 }}
              showIcon
            />
          </Item>
        )}
      </Section>
      {expiredContracts.length > 0 && (
        <>
          <Header title={t`History`} padding="10px 45px" />
          <Section>
            {expiredContracts.map(contract => (
              <Item key={contract.id}>
                <UsageLimitCollapse type={PEOPLE_CREDITS} {...contract} />
              </Item>
            ))}
          </Section>
        </>
      )}
    </>
  );
};

export default PeopleCredits;

PeopleCredits.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      used: PropTypes.number.isRequired,
      limit: PropTypes.number.isRequired,
    })
  ).isRequired,
};
