import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'ttag';

export const NOT_STARTED = 'not_started';
export const PENDING = 'pending';
export const FAILED = 'failed';
export const VERIFIED = 'verified';
export const EXPIRED = 'expired';

/**
 * A component that displays the status of a domain
 * @param {string} props.status The status (pending, failed, verified, not_started) that should
 *   be displayed
 * @param {boolean} props.animate Whether the pending spinner should animate
 * @param {boolean} props.pendingIsVerifying Whether the pending status should be titled as
 *   'Verifying'. This is used in the View Records modal to tie in with the Verify Now button
 */
const DomainStatusTag = ({ status, animate = true, pendingIsVerifying = false }) => {
  switch (status) {
    case PENDING:
      return (
        <Tag color="processing" icon={<SyncOutlined spin={animate} />}>
          {pendingIsVerifying ? t`Verifying` : t`Pending`}
        </Tag>
      );
    case FAILED:
      return <Tag color="error" icon={<CloseCircleOutlined />}>{t`Failed`}</Tag>;
    case VERIFIED:
      return <Tag color="success" icon={<CheckCircleOutlined />}>{t`Connected`}</Tag>;
    case EXPIRED:
      return <Tag color="warning" icon={<ExclamationCircleOutlined />}>{t`Expired`}</Tag>;
    case NOT_STARTED:
    default:
      return <Tag>{t`N/A`}</Tag>;
  }
};

DomainStatusTag.propTypes = {
  status: PropTypes.oneOf([PENDING, FAILED, VERIFIED, NOT_STARTED]).isRequired,
  animate: PropTypes.bool,
  pendingIsVerifying: PropTypes.bool,
};

export default DomainStatusTag;
