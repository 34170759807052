import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { list as listFn, create as createFn, verify as verifyFn, remove as removeFn } from './service';

const VERIFY_CUSTOM_DOMAIN_INTERVAL = 10 * 1000;

export const listQueryOptions = organizationId => ({
  queryKey: ['organization', organizationId, 'custom-domain'],
  queryFn: () => listFn(organizationId),
});

export const useList = organizationId => useQuery(listQueryOptions(organizationId));

export const useCreateCustomDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['customDomain', 'create'],
    mutationFn: ({ organizationId, payload }) => createFn(organizationId, payload),
    onSuccess: (data, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: listQueryOptions(organizationId).queryKey });
    },
  });
};

export const useVerifyCustomDomain = id => {
  const { organizationId } = useParams();
  return useQuery({
    queryKey: ['customDomain', 'verify'],
    queryFn: () => verifyFn(organizationId, id),
    refetchInterval: prev =>
      prev?.data.dnsRecords.length === 0 ? VERIFY_CUSTOM_DOMAIN_INTERVAL : false,
    enabled: !!id,
    cacheTime: 0,
  });
}

export const useRemoveCustomDomainMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['custom-domain', 'remove'],
    mutationFn: ({ organizationId, ids }) =>
      Promise.all(ids.map(id => removeFn(organizationId, id))),
    onSuccess: (data, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: listQueryOptions(organizationId).queryKey });
    }
  });
}
