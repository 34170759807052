import { Button, Space, Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'ttag';

import ShowMore from 'common/components/ShowMore';
import { useTableQuery } from 'common/hooks/useTableQuery';
import { getId } from 'common/state/organization';

import DomainStatusTag, {
  FAILED,
  NOT_STARTED,
  PENDING,
  VERIFIED,
} from '@components/DomainStatusTag';
import usePermission, { DELETE_SENDER_EMAIL_DOMAINS } from '@hooks/usePermission';

import { list } from './queries';

const EmailDomainTable = ({ viewRecords, onDelete, onRowSelection, selectedRowKeys }) => {
  const organizationId = useSelector(getId);
  const { authorized } = usePermission();

  const columns = useMemo(
    () => [
      {
        title: t`Domain Name`,
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: t`Used By`,
        dataIndex: 'linkedEvents',
        key: 'linkedEventId',
        derivedFilters: data =>
          Object.entries(
            data.reduce((acc, domain) => {
              domain.linkedEvents.forEach(event => {
                acc[event.id] = event.code;
              });
              return acc;
            }, {})
          ).map(([id, code]) => ({ text: <Tag>{code}</Tag>, value: id })),
        render: linkedEvents => (
          <ShowMore
            items={linkedEvents.map(({ id, code: name }) => ({ id, name }))}
            isTag
            uppercase={false}
          />
        ),
      },
      {
        title: t`Verification Status`,
        dataIndex: 'verificationStatus',
        render: status => <DomainStatusTag status={status} />,
        filters: [
          {
            text: <DomainStatusTag status={NOT_STARTED} />,
            value: NOT_STARTED,
          },
          {
            text: <DomainStatusTag status={PENDING} animate={false} />,
            value: PENDING,
          },
          {
            text: <DomainStatusTag status={FAILED} />,
            value: FAILED,
          },
          {
            text: <DomainStatusTag status={VERIFIED} />,
            value: VERIFIED,
          },
        ],
      },
      {
        title: t`Action`,
        key: 'action',
        // eslint-disable-next-line react/prop-types
        render: row => (
          <Space>
            <Button type="link" onClick={() => viewRecords(row.id)}>{t`View Records`}</Button>
            {authorized(DELETE_SENDER_EMAIL_DOMAINS) && (
              <Button type="link" onClick={() => onDelete([row])} danger>{t`Delete`}</Button>
            )}
          </Space>
        ),
      },
    ],
    [onDelete, viewRecords]
  );

  const { tableProps } = useTableQuery(
    apiParams => ({
      queryKey: list.getQueryKey(organizationId, apiParams),
      queryFn: list.queryFn,
    }),
    {
      pagination: false,
      columns,
      rowKey: 'id',
      rowSelection: authorized(DELETE_SENDER_EMAIL_DOMAINS)
        ? {
            onChange: onRowSelection,
            selectedRowKeys,
          }
        : null,
    }
  );

  return <Table {...tableProps} />;
};

EmailDomainTable.propTypes = {
  onDelete: PropTypes.func.isRequired,
  viewRecords: PropTypes.func.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmailDomainTable;
