import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Input,
  Modal,
  Space,
  Spin,
  Table as AntTable,
  Tooltip,
  Typography,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import CopyButton from '@components/CopyButton';
import DomainStatusTag, {
  FAILED,
  NOT_STARTED,
  PENDING,
  VERIFIED,
} from '@components/DomainStatusTag';
import usePermission, {
  DELETE_SENDER_EMAIL_DOMAINS,
  VERIFY_SENDER_EMAIL_DOMAINS,
} from '@hooks/usePermission';

import { useKBArticle } from './constants';
import { useEmailDomain, useVerifyEmailDomainMutation } from './queries';

// Turn off row highlighting on hover, and set the host/value columns to a darker background
const Table = styled(AntTable)`
  tbody tr:hover td {
    background: none !important;
  }
  tbody .darkBackground,
  tbody tr:hover .darkBackground {
    background: #fafafa !important;
  }
`;

const TypeWithTooltip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewRecordsContent = ({ isLoading, isVerifying, data }) => {
  const kbArticle = useKBArticle();

  /**
   * Formated DNS records and instructions that can be copied to the clipboard as both plain and
   * formatted text. This aims for a readable table regardless of where it is pasted by using
   * both <pre> tags and markdown code blocks (```)
   */
  const textForCopyingInstructions = useMemo(() => {
    if (!data?.dnsRecords) {
      return null;
    }

    const recordsWithHeader = [
      { type: t`Type`, host: t`Host`, value: t`Value` },
      ...data.dnsRecords,
    ];

    /**
     * Calculate the width of the columns to pad the values in the table. We don't need to pad the
     * last column, so we don't calculate its length
     */
    const colWidths = recordsWithHeader.reduce(
      (acc, next) => [Math.max(acc[0], next.type.length), Math.max(acc[1], next.host.length)],
      [0, 0]
    );

    const formattedRecords = recordsWithHeader
      .map(({ type, host, value }) =>
        [type.toUpperCase().padEnd(colWidths[0]), host.padEnd(colWidths[1]), value].join('   ')
      )
      .join('\n');

    const blobs = ['text/html', 'text/plain'].reduce((acc, type) => {
      // We use a breakTag variable in the translations since we need to render a <br /> in HTML
      // but just a linebreak (\n) in plain text, and the \n is part of the template string already
      const breakTag = type === 'text/html' ? '<br />' : '';
      const wrappedFormattedRecords =
        type === 'text/html'
          ? `<pre style="font: monospaced">${formattedRecords}</pre>` // HTML format
          : `\`\`\`\n${formattedRecords}\n\`\`\``; // Markdown format
      const content = t`\
Please utilize the provided records to configure your SPF, DKIM, and DMARC within your DNS provider. Once set up, you can proceed to verify the domain in Experience Manager.${breakTag}
${breakTag}
If DMARC is already set up for your domain, we advise maintaining your current policy to prevent any unforeseen alterations to how your organization manages unauthenticated emails.${breakTag}
If DMARC is not yet configured for your domain, we recommend implementing it with a "none" policy to maintain your current approach to handling unauthenticated emails.${breakTag}
${breakTag}
Records:${breakTag}
${wrappedFormattedRecords}`;
      const footer =
        type === 'text/html'
          ? t`If you need help to set up SPF, DKIM, and DMARC, you can learn more about the <a href="${kbArticle}">email sender guidelines</a> or reach out to <a href="mailto:support@eventmobi.com">support@eventmobi.com</a>.`
          : t`If you need help to set up SPF, DKIM, and DMARC, you can learn more about the email sender guidelines at ${kbArticle} or reach out to support@eventmobi.com.`;

      const fullText = `${content}\n\n${breakTag}${footer}`;
      return { ...acc, [type]: new Blob([fullText], { type }) };
    }, {});

    return new ClipboardItem(blobs);
  }, [data, kbArticle]);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  }

  return (
    <Space size="large" direction="vertical" style={{ width: '100%' }}>
      <Space size="small" direction="vertical" style={{ width: '100%' }}>
        <Typography>{t`Domain`}</Typography>
        <Space.Compact block>
          <Input disabled value={data.name} />
          <Button type="primary" disabled>{t`Generate Records`}</Button>
        </Space.Compact>
      </Space>

      <Typography>
        {t`DNS Records have been generated. Please configure these records in your DNS provider to complete the process. We recommend you to reach out to your IT department for assistance.  Once verified please do not remove these records.`}
      </Typography>

      <CopyButton
        toCopy={textForCopyingInstructions}
      >{t`Copy records & set up instructions`}</CopyButton>

      <Table
        rowKey="id"
        columns={[
          {
            title: t`Status`,
            dataIndex: 'verificationStatus',
            render: status => <DomainStatusTag pendingIsVerifying status={status} />,
          },
          {
            title: t`Type`,
            dataIndex: 'type',
            render: type => {
              if (type !== 'txt') {
                return type.toUpperCase();
              }
              return (
                <TypeWithTooltip>
                  {type.toUpperCase()}
                  <Tooltip
                    title={t`DMARC is required for domain verification. If you already have DMARC set up, no updates to its settings are needed.`}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </TypeWithTooltip>
              );
            },
          },
          {
            title: t`Host`,
            dataIndex: 'host',
            className: 'darkBackground',
            render: host => (
              <>
                {host}
                <CopyButton toCopy={host} />
              </>
            ),
          },
          {
            title: t`Value`,
            dataIndex: 'value',
            className: 'darkBackground',
            render: value => (
              <>
                {value}
                <CopyButton toCopy={value} />
              </>
            ),
          },
        ]}
        dataSource={data.dnsRecords}
        pagination={false}
      />

      <Typography>
        {t`Need help?`}{' '}
        <Typography.Link href={kbArticle} target="_blank">
          {t`Learn about email sender guidelines here.`}
        </Typography.Link>
      </Typography>

      {isVerifying && (
        <Alert
          message={t`We are verifying your domain. This can take up to 48 hours, we will contact you once verification is complete.`}
          type="warning"
          showIcon
        />
      )}
    </Space>
  );
};

ViewRecordsContent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isVerifying: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    // Note: These are not all of the fields that flux returns, just the ones that we use.
    // The full type is listed as a commented out interface in service.js
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    verificationStatus: PropTypes.oneOf([NOT_STARTED, PENDING, VERIFIED, FAILED]).isRequired,
    dnsRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['txt', 'cname']).isRequired,
        host: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        verificationStatus: PropTypes.oneOf([NOT_STARTED, PENDING, VERIFIED, FAILED]).isRequired,
      })
    ).isRequired,
  }),
};

const ViewRecordsModal = ({ organizationId, domainId, onClose, onDelete }) => {
  const { data, isLoading } = useEmailDomain(organizationId, domainId);
  const verificationInProgress = data?.data?.verificationStatus === 'pending';

  const { mutate: mutateVerify, isLoading: isVerifyLoading } = useVerifyEmailDomainMutation();
  const { authorized } = usePermission();

  let verifyButtonLabel = t`Verify Now`;
  if (data?.data?.verificationStatus === 'verified') {
    verifyButtonLabel = t`Reverify Now`;
  } else if (verificationInProgress) {
    verifyButtonLabel = t`Verifying`;
  }

  return (
    <Modal
      title={t`View Records`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={() => onClose()}
      footer={
        <Row justify="space-between">
          <Space>
            {authorized(DELETE_SENDER_EMAIL_DOMAINS) && (
              <Button
                key="delete"
                type="danger"
                onClick={() => onDelete([data?.data])}
                icon={<DeleteOutlined />}
                disabled={isLoading}
              >
                {t`Delete`}
              </Button>
            )}
            <Button key="close" onClick={() => onClose()}>
              {t`Close`}
            </Button>
          </Space>
          {authorized(VERIFY_SENDER_EMAIL_DOMAINS) && (
            <Button
              key="verify"
              type="primary"
              onClick={() =>
                mutateVerify(
                  { organizationId, id: domainId },
                  {
                    onSuccess: result => {
                      // If the domain immediately verified, close the modal
                      if (result?.data?.verificationStatus === 'verified') {
                        onClose();
                      }
                    },
                  }
                )
              }
              disabled={isLoading}
              loading={verificationInProgress || isVerifyLoading}
            >
              {verifyButtonLabel}
            </Button>
          )}
        </Row>
      }
      width={1000}
    >
      <ViewRecordsContent
        organizationId={organizationId}
        domainId={domainId}
        onClose={onClose}
        isLoading={isLoading}
        isVerifying={verificationInProgress || isVerifyLoading}
        data={data?.data}
      />
    </Modal>
  );
};

ViewRecordsModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  domainId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ViewRecordsModal;
