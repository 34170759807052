function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _asyncToGenerator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpreadProps(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var __generator = this && this.__generator || function(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return(g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g);
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
};
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { getEvent, getId as getEventId } from "../state/event";
import { update as updateConf } from "../services/config/api";
import { checkPortal } from "../state/system";
import { useConfigKeysQuery, useConfigQuery } from "../services/config";
var FeatureFlagStatus;
(function(FeatureFlagStatus) {
    FeatureFlagStatus["Enabled"] = "1";
    FeatureFlagStatus["Disabled"] = "0";
})(FeatureFlagStatus || (FeatureFlagStatus = {}));
// VALID_NULLABLE_CONFIG_KEYS is the list of configuration keys that can have a null
// as valid value.
// These configurations should not be considered as configs with missing values and
// be replaced with default value if the value is null.
//
// One good example is `event_max_duration_days` which will have a null value if the
// event does not have a max duration set; otherwise it will have a number value.
var VALID_NULLABLE_CONFIG_KEYS = [
    "event_max_duration_days",
    "reg_open_period"
];
var selector = createSelector(getEventId, getEvent, checkPortal, function(eventId, param, param1) {
    var timezone = param.timezone, isExperiencePortal = param1.isExperiencePortal;
    return {
        eventId: eventId,
        timezone: timezone,
        isExperiencePortal: isExperiencePortal
    };
});
var useConfig = function() {
    var _useSelector = useSelector(selector), eventId = _useSelector.eventId, timezone = _useSelector.timezone, isExperiencePortal = _useSelector.isExperiencePortal;
    var queryClient = useQueryClient();
    var keysQuery = useConfigKeysQuery([], {
        enabled: isExperiencePortal
    });
    var configQuery = useConfigQuery([
        eventId
    ], {
        enabled: !!eventId
    });
    // react-query return isLoading as true when the enabled flag is false
    // so in the case of CompanyPortal the keysQuery.isLoading will always return true
    // hence added one more check - !isExperiencePortal
    var loading = keysQuery.isLoading && isExperiencePortal || configQuery.isLoading;
    var keys = keysQuery.data;
    var conf = configQuery.data;
    // gets the value of a record from the configuration array stored in the state.
    // in case of key not being existent in the configuration object, it looks up
    // in the keys store and returns the default value (returns the first one when
    // there are multiple default values), if it doesn't exist in the default keys
    // array either then it return null
    var get = useCallback(function(key) {
        var _targetKey_default;
        var targetConfig = conf === null || conf === void 0 ? void 0 : conf.find(function(c) {
            return c.key === key;
        });
        var targetKey = keys === null || keys === void 0 ? void 0 : keys.find(function(k) {
            return k.key === key;
        });
        if (typeof targetConfig === "undefined" && typeof targetKey === "undefined") {
            return null;
        }
        // some config keys may have null as valid value; if this key is among those
        // and the value is null, return null - and do not use the default value
        if (VALID_NULLABLE_CONFIG_KEYS.includes(key) && (targetConfig === null || targetConfig === void 0 ? void 0 : targetConfig.value) === null) {
            return null;
        }
        if ((targetKey === null || targetKey === void 0 ? void 0 : targetKey.type) === "datetime_range" && (targetConfig === null || targetConfig === void 0 ? void 0 : targetConfig.value)) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            var _JSON_parse = JSON.parse(targetConfig.value), start_datetime = _JSON_parse.start_datetime, end_datetime = _JSON_parse.end_datetime;
            return [
                moment.tz(start_datetime, timezone),
                moment.tz(end_datetime, timezone)
            ];
        }
        // if value property exists in the target returns that, other wise look for the
        // default property, if that exists then splits the value of default using
        // `,` character so that it will return the first value in case there are multiple
        // default values; in rare scenario of neither value nor default property being
        // defined, return null
        var confValue = targetConfig === null || targetConfig === void 0 ? void 0 : targetConfig.value;
        var keyValue = targetKey === null || targetKey === void 0 ? void 0 : (_targetKey_default = targetKey.default) === null || _targetKey_default === void 0 ? void 0 : _targetKey_default.split(",")[0];
        if (confValue !== undefined) {
            return confValue;
        }
        if (keyValue !== undefined) {
            return keyValue;
        }
        return null;
    }, [
        keys,
        conf,
        timezone
    ]);
    var find = useCallback(function(key) {
        return conf.find(function(c) {
            return c.key === key;
        });
    }, [
        conf
    ]);
    // asynchronous function to either update a configuration object or create a new one
    // if it does not exist; returns the newly created/update record
    var set = useCallback(function() {
        var _ref = _asyncToGenerator(function(key, value) {
            var target, record;
            return __generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        target = conf.find(function(c) {
                            return c.key === key;
                        });
                        if (typeof target === "undefined") {
                            throw new Error("invalid configuration key ".concat(key));
                        }
                        return [
                            4,
                            updateConf(eventId, target.id, _objectSpreadProps(_objectSpread({}, target), {
                                value: value
                            }))
                        ];
                    case 1:
                        record = _state.sent();
                        queryClient.invalidateQueries({
                            queryKey: [
                                "config"
                            ]
                        });
                        return [
                            2,
                            record
                        ];
                }
            });
        });
        return function(key, value) {
            return _ref.apply(this, arguments);
        };
    }(), [
        conf,
        eventId,
        queryClient
    ]);
    // checks for the provided key in the configuration array stored in state,
    // return `true` if it exists AND its value is set to `1`
    var isEnabled = useCallback(function(key) {
        var value = get(key);
        return value !== null && value === FeatureFlagStatus.Enabled;
    }, [
        get
    ]);
    // checks for the provided key in the configuration array stored in state,
    // return `true` if it exists AND its value is set to `0`
    var isDisabled = useCallback(function(key) {
        var value = get(key);
        return value === null || value === FeatureFlagStatus.Disabled;
    }, [
        get
    ]);
    // returns all enabled configuration settings
    var enabled = useCallback(function() {
        return conf.filter(function(c) {
            return (c === null || c === void 0 ? void 0 : c.value) === FeatureFlagStatus.Enabled;
        });
    }, [
        conf
    ]);
    // returns all disabled configuration settings
    var disabled = useCallback(function() {
        return conf.filter(function(c) {
            return (c === null || c === void 0 ? void 0 : c.value) === FeatureFlagStatus.Disabled;
        });
    }, [
        conf
    ]);
    return {
        get: get,
        find: find,
        set: set,
        isEnabled: isEnabled,
        isDisabled: isDisabled,
        enabled: enabled,
        disabled: disabled,
        loading: loading
    };
};
export default useConfig;
