import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputNumber as AntInputNumber,
  message,
  Space,
  Typography,
  Radio,
  Checkbox,
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from 'ttag';

import Header from 'common/components/Header';
import Label from 'common/components/Label';
import StickyFooter from 'common/components/StickyFooter';
import { setOrganization, getOrganization } from 'common/state/organization';

import {
  LICENSE_TYPE_EVENT_CREDIT,
  LICENSE_TYPE_USER_CREDIT,
  update as updateFn,
} from '../service';

import Contracts from './Contracts';

const { Item, useForm } = Form;

const Base = styled.div`
  padding-top: 20px;

  /* accommodate saveBar component's height */
  margin-bottom: 80px;
`;

const SubTitle = styled(Typography.Title)`
  && {
    margin-bottom: 16px;
    font-weight: 400;
  }
`;

const Section = styled.div`
  max-width: ${({ width }) => width ?? 500}px;
  margin: 10px 0px 10px 45px;
`;

const InputNumber = styled(AntInputNumber)`
  width: 100%;
`;

const Admin = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const {
    id,
    name,
    crmId: crmOrganizationId,
    isInternal,
    licenseType,
    configuration,
  } = useSelector(getOrganization);

  const [form] = useForm();
  const { validateFields, getFieldValue } = form;

  const onSave = async () => {
    setError(null);
    try {
      setSaving(true);
      const values = await validateFields();
      const payload = {
        ...values,
        configuration: values.configuration.map((config, i) => ({
          id: configuration[i].id,
          value: config.value?.toString(),
        })),
      };
      const organization = await updateFn(id, payload);

      dispatch(setOrganization(organization));
      message.success(t`Successfully saved changes.`);
    } catch (err) {
      if (!err.errorFields) {
        setError(
          err?.errors?.[0]?.message ?? t`An unknown error occurred while saving organization.`
        );
        throw err;
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <Base>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          initialValues={{
            name,
            crmOrganizationId,
            isInternal,
            licenseType,
            configuration,
          }}
        >
          <Section>
            {error && (
              <Item>
                <Alert showIcon type="error" message={error} />
              </Item>
            )}
            <Item
              name="name"
              label={<Label isRequired>{t`Organization Name`}</Label>}
              rules={[{ required: true, message: t`Organization Name is required.` }]}
            >
              <Input />
            </Item>
            <Item name="crmOrganizationId" label={<Label>{t`Hubspot Organization ID`}</Label>}>
              <Input />
            </Item>
            <Item name="isInternal" valuePropName="checked">
              <Checkbox disabled>
                {t`Internal Use Only`}
                <br />
                {t`This is used for internal analytics and CRM automation. This can only be changed by our engineering team.`}
              </Checkbox>
            </Item>
          </Section>
          <Header title={t`License Type`} padding="10px 45px" />
          <Section width={850}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Item name="licenseType" noStyle>
                <Radio.Group>
                  <Space size="middle" direction="vertical">
                    <Radio value={LICENSE_TYPE_EVENT_CREDIT}>
                      {t`Event Credit License`}
                      <br />
                      <Typography.Text type="secondary">{t`Create events using event credits, with a people limit for each event.`}</Typography.Text>
                    </Radio>
                    <Radio value={LICENSE_TYPE_USER_CREDIT}>
                      {t`People Credit License`}
                      <br />
                      <Typography.Text type="secondary">{t`Create unlimited events per contract, with People Credit per organization.`}</Typography.Text>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Item>
              <Item noStyle shouldUpdate>
                {() => <Contracts licenseType={getFieldValue('licenseType')} />}
              </Item>
            </Space>
          </Section>
          <Header title={t`Video Usage Configuration`} padding="10px 45px" />
          <Section>
            {configuration?.map((config, index) => {
              const isNewCategory =
                config.category_name !== configuration[index - 1]?.category_name;
              return (
                <div key={config.id}>
                  {index !== 0 && isNewCategory && <Divider style={{ margin: '35px 0' }} />}
                  {isNewCategory && <SubTitle level={4}>{config.category_name}</SubTitle>}
                  <Item
                    label={<Label info={config.description}>{config.name}</Label>}
                    name={['configuration', index, 'value']}
                    rules={[
                      { required: true, message: t`Value is required.` },
                      {
                        validator: async (rule, value) => {
                          if (value < 0) {
                            throw new Error('error');
                          }
                        },
                        message: t`Invalid value. The value must be equal or larger than 0.`,
                      },
                    ]}
                  >
                    <InputNumber precision={0} />
                  </Item>
                </div>
              );
            })}
          </Section>
        </Form>
      </Space>
      <StickyFooter fullWidth>
        <Button type="primary" loading={saving} onClick={onSave}>
          {t`Save`}
        </Button>
      </StickyFooter>
    </Base>
  );
};

export default Admin;
