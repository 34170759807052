import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table as AntTable,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';

import CopyButton from '@components/CopyButton';
import DomainStatusTag from '@components/DomainStatusTag';

import { useCreateCustomDomainMutation, useVerifyCustomDomain } from './queries';

const { Item, useForm } = Form;
const { Text, Link } = Typography;

const KNOWLEDGE_BASE_LINK =
  'https://help.eventmobi.com/en/knowledge/can-i-change-my-app-address-or-use-a-custom-domain';

/**
 * Formated DNS records and instructions that can be copied to the clipboard as both plain and
 * formatted text. This aims for a readable table regardless of where it is pasted by using
 * both <pre> tags and markdown code blocks (```)
 */
const getTextForCopyingInstructions = dnsRecords => {
  if (!dnsRecords.length) {
    return null;
  }

  const recordsWithHeader = [{ type: t`Type`, host: t`Host`, value: t`Value` }, ...dnsRecords];

  /**
   * Calculate the width of the columns to pad the values in the table. We don't need to pad the
   * last column, so we don't calculate its length
   */
  const colWidths = recordsWithHeader.reduce(
    (acc, next) => [Math.max(acc[0], next.type.length), Math.max(acc[1], next.host.length)],
    [0, 0]
  );

  const formattedRecords = recordsWithHeader
    .map(({ type, host, value }) =>
      [type.toUpperCase().padEnd(colWidths[0]), host.padEnd(colWidths[1]), value].join('   ')
    )
    .join('\n');

  const blobs = ['text/html', 'text/plain'].reduce((acc, type) => {
    // We use a breakTag variable in the translations since we need to render a <br /> in HTML
    // but just a linebreak (\n) in plain text, and the \n is part of the template string already
    const breakTag = type === 'text/html' ? '<br />' : '';
    const wrappedFormattedRecords =
      type === 'text/html'
        ? `<pre style="font: monospaced">${formattedRecords}</pre>` // HTML format
        : `\`\`\`\n${formattedRecords}\n\`\`\``; // Markdown format
    const content = t`\
Please utilize the provided records to configure your CNAME within your DNS provider.${breakTag}
${breakTag}
On your domain provider’s website, log in to your account, find the DNS settings or domain management area, update or add the following records:${breakTag}
${breakTag}
Records:${breakTag}
${wrappedFormattedRecords}`;
    const footer =
      type === 'text/html'
        ? t`If you need help to set up CNAME Records, you can learn more about <a href=${KNOWLEDGE_BASE_LINK}>Custom Domain</a> or reach out to <a href="mailto:support@eventmobi.com">support@eventmobi.com</a>.`
        : t`If you need help to set up CNAME Records, you can learn more about Custom Domain at ${KNOWLEDGE_BASE_LINK} or reach out to support@eventmobi.com.`;

    const fullText = `${content}\n\n${breakTag}${footer}`;
    return { ...acc, [type]: new Blob([fullText], { type }) };
  }, {});

  return new ClipboardItem(blobs);
};

export const CUSTOM_DOMAIN_EXISTS_ERROR = 'custom_domain_already_exists';
const ERROR_MESSAGES_BY_CODE = () => ({
  custom_domain_invalid: t`Invalid subdomain provided.`,
  custom_domain_limit_reached: t`Custom domain limit reached or exceeded.`,
});

// Turn off row highlighting on hover, and set the host/value columns to a darker background
const Table = styled(AntTable)`
  tbody tr:hover td {
    background: none !important;
  }
  tbody .darkBackground,
  tbody tr:hover .darkBackground {
    background: #fafafa !important;
  }
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WrappedText = styled.span`
  word-break: break-word;
  max-width: 85%;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

const AddModal = ({ organizationId, onClose }) => {
  const [domainId, setDomainId] = useState('');
  const [existingDomain, setExistingDomain] = useState('');
  const [dnsRecords, setDnsRecords] = useState([]);
  const [formInvalid, setFormInvalid] = useState(true);
  const { mutate: createCustomDomain, isLoading } = useCreateCustomDomainMutation();
  const { data } = useVerifyCustomDomain(domainId);

  const textForCopyingInstructions = useMemo(() => getTextForCopyingInstructions(dnsRecords), [
    dnsRecords,
  ]);

  const [form] = useForm();

  useEffect(() => {
    if (data?.data?.dnsRecords) {
      setDnsRecords(data.data.dnsRecords);
    }
  }, [data]);

  const handleSave = async () => {
    try {
      const payload = await form.validateFields();

      createCustomDomain(
        { organizationId, payload },
        {
          onSuccess: res => {
            setDomainId(res.data.id);
          },
          onError: err => {
            if (err?.errors?.[0]?.code === CUSTOM_DOMAIN_EXISTS_ERROR) {
              setExistingDomain(payload.customDomain);
              form.validateFields(['customDomain']);
            } else {
              const errorMessage =
                ERROR_MESSAGES_BY_CODE()[err?.errors?.[0]?.code] ?? t`An unknown error occurred`;
              message.error(errorMessage);
            }
          },
        }
      );
    } catch (err) {
      if (err?.errorFields) return;
      message.error(t`An unknown error occurred`);
    }
  };

  return (
    <Modal
      title={dnsRecords.length ? t`View Records` : t`Add Custom Domain`}
      open
      maskClosable={false}
      destroyOnClose
      onCancel={onClose}
      footer={
        domainId ? (
          <Button onClick={onClose}>{t`Close`}</Button>
        ) : (
          <>
            <Button onClick={onClose}>{t`Cancel`}</Button>
            <Button
              disabled={formInvalid}
              loading={isLoading}
              onClick={handleSave}
              type="primary"
            >{t`Next`}</Button>
          </>
        )
      }
      width={1000}
    >
      <Space size="middle" direction="vertical" style={{ width: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFieldsChange={() => {
            setFormInvalid(form.getFieldsError().some(item => item.errors.length > 0));
          }}
        >
          <Item
            label={
              <LabelContainer>
                <Label isRequired>{t`Custom Domain`}</Label>
                {!dnsRecords.length > 0 && (
                  <Text type="secondary">
                    {t`Enter the sub domain you want to connect to your events.`}
                  </Text>
                )}
              </LabelContainer>
            }
            style={{ marginBottom: 0 }}
            name="customDomain"
            rules={[
              {
                required: true,
                message: t`Only subdomains are allowed. Please enter a valid subdomain (e.g., event.eventmobi.com)`,
                pattern: /^(?!localhost)(?=.*\.[A-Za-z]{2,}$)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.){2,}[A-Za-z]{2,}$/,
              },
              {
                validator(_, value) {
                  if (existingDomain && value === existingDomain) {
                    setFormInvalid(true);
                    return Promise.reject(
                      t`This subdomain already exists. Please use different domain or delete the existing domain and try again.`
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input disabled={!!domainId} placeholder="example.yourdomain.com" />
          </Item>
        </Form>
        {dnsRecords.length > 0 && (
          <>
            <Typography>
              {t`CNAME Records have been generated. Please configure these records on your domain provider’s website. We recommend you to reach out to your IT department for assistance.  Once verified please do not remove these records.`}
            </Typography>
            <CopyButton
              toCopy={textForCopyingInstructions}
            >{t`Copy records & set up instructions`}</CopyButton>
            <Table
              rowKey="value"
              columns={[
                {
                  title: t`Status`,
                  dataIndex: 'status',
                  render: status => <DomainStatusTag status={status} />,
                },
                {
                  title: t`Type`,
                  dataIndex: 'type',
                  render: type => type.toUpperCase(),
                },
                {
                  title: t`Name`,
                  dataIndex: 'host',
                  className: 'darkBackground',
                  render: host => (
                    <InlineContainer>
                      <WrappedText>{host}</WrappedText>
                      <CopyButton toCopy={host} />
                    </InlineContainer>
                  ),
                },
                {
                  title: t`Value`,
                  dataIndex: 'value',
                  className: 'darkBackground',
                  render: value => (
                    <InlineContainer>
                      <WrappedText>{value}</WrappedText>
                      <CopyButton toCopy={value} />
                    </InlineContainer>
                  ),
                },
              ]}
              dataSource={dnsRecords}
              pagination={false}
            />
          </>
        )}
        <Text>
          {t`Need Help? `}
          <Link
            href={KNOWLEDGE_BASE_LINK}
            target="_blank"
          >{t`Learn more about Custom Domain.`}</Link>
        </Text>
        {domainId && !dnsRecords.length > 0 && (
          <Alert
            type="warning"
            showIcon
            message={t`Generating your CNAME records might take a few minutes. You can safely close this modal and we would notify you once the records are ready.`}
          />
        )}
        {dnsRecords.length > 0 && (
          <Alert
            type="info"
            showIcon
            message={
              <>
                {t`Connect your custom domain following provided instructions. Configuration must be completed within `}
                <Text strong>{t`72 hours`}</Text>.
              </>
            }
          />
        )}
      </Space>
    </Modal>
  );
};

AddModal.propTypes = {
  organizationId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddModal;
