import apiFn from 'common/services/api-service';

const api = (eventId, headers = { Accept: 'application/vnd.eventmobi+json; version=p.4' }) =>
  apiFn(`/api/uapi/events/${eventId}/people/fields`, headers);

export function get(eventId, id) {
  return api(eventId)
    .get(id.toString())
    .then(response => response?.data?.data);
}

export function list(eventId, extraParams = {}) {
  const params = new URLSearchParams(extraParams);

  return api(eventId)
    .get(null, { params })
    .then(response => response?.data?.data);
}

export function listNew(eventId, extraParams = {}) {
  const headers = {};
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';

  const params = new URLSearchParams(extraParams);

  return api(eventId, headers)
    .get(null, { params })
    .then(response => response?.data?.data);
}

export function create(eventId, params) {
  return api(eventId)
    .post('', params)
    .then(response => response?.data?.data);
}

export function update(eventId, fieldId, data) {
  return api(eventId)
    .patch(fieldId.toString(), data)
    .then(response => response?.data?.data);
}

export function remove(eventId, fieldId) {
  return api(eventId)
    .delete(fieldId.toString())
    .then(response => response?.data?.data);
}

export function getNew(eventId, fieldId) {
  const headers = {};
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';

  const params = new URLSearchParams({ include: 'field_links' });

  return api(eventId, headers)
    .get(fieldId.toString(), { params })
    .then(response => response?.data?.data);
}

export function reorder(eventId, data) {
  return apiFn(`/api/uapi/events/${eventId}/people/fields/reorder`)
    .post('', data)
    .then(response => response?.data?.data);
}

export function reorderNew(eventId, data) {
  const headers = {};
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';
  return apiFn(`/api/uapi/events/${eventId}/people/fields`, headers)
    .patch('', data)
    .then(response => response?.data?.data);
}

export function createNew(eventId, data) {
  const headers = {
    'x-nested-list-partial-updates': 'false',
  };
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';
  return apiFn(`/api/uapi/events/${eventId}/people/fields`, headers)
    .post('', data)
    .then(response => response?.data?.data);
}

export function updateNew(
  eventId,
  fieldId,
  payload,
  { nestedListPartialUpdates } = { nestedListPartialUpdates: false }
) {
  const headers = {
    'x-nested-list-partial-updates': nestedListPartialUpdates ? 'true' : 'false',
  };
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';
  return apiFn(`/api/uapi/events/${eventId}/people/fields/${fieldId}`, headers)
    .patch('', payload)
    .then(response => response?.data?.data);
}

// Remove the field for a certain product
export function unlink(eventId, fieldId, productType) {
  const headers = {};
  headers.Accept = 'application/vnd.eventmobi+json; version=p.7';
  return apiFn(`/api/uapi/events/${eventId}/people/fields/${fieldId}`, headers)
    .patch('', {
      fieldLinks: [
        {
          linkType: productType,
          deleted: true,
        },
      ],
    })
    .then(response => response?.data?.data);
}
