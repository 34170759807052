import { Col, Input, Row, Select, Form, message, Button, Typography } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import Label from 'common/components/Label';
import Loading from 'common/components/Loading';
import StickyFooter from 'common/components/StickyFooter';

import areas from '../constants/areas';
import countries from '../constants/countries';
import { useEventInfo, useEventInfoMutation } from '../queries';

const { Text } = Typography;

const filterByLabelOrValue = (inputValue, { label, value }) =>
  label.toLowerCase().includes(inputValue.toLowerCase()) ||
  value.toLowerCase().includes(inputValue.toLowerCase());

const Item = styled(Form.Item)`
  && {
    margin-bottom: 16px;
  }
`;

const CompanyInfo = () => {
  const [form] = useForm();
  const { data, isLoading } = useEventInfo();
  const { mutate, isLoading: isSaving } = useEventInfoMutation();
  const countryCode = useWatch('countryCode', form);
  const countryDetails = countries().find(({ iso2 }) => iso2 === countryCode);
  const countryStates = areas[countryDetails?.iso3];
  const countryAreasTerm = countryDetails?.type || t`Province/State`;

  const resetAddress = () => {
    form.setFieldsValue({
      postalCode: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
    });
  };

  const resetToDefaults = () => {
    form.setFieldsValue({
      company: data.companyAddress?.company,
      countryCode: data.companyAddress?.countryCode,
      postalCode: data.companyAddress?.postalCode,
      addressLine1: data.companyAddress?.addressLine1,
      addressLine2: data.companyAddress?.addressLine2,
      city: data.companyAddress?.city,
      state: data.companyAddress?.state,
    });
  };

  const submit = values => {
    mutate(
      {
        companyAddress: {
          company: values.company || null,
          countryCode: values.countryCode || null,
          addressLine_1: values.addressLine1 || null,
          addressLine_2: values.addressLine2 || null,
          city: values.city || null,
          state: values.state || null,
          postalCode: values.postalCode || null,
        },
      },
      {
        onSuccess: () => {
          message.success(t`Successfully saved settings.`);
        },
        onError: () => {
          message.error(t`Error saving settings.`);
        },
      }
    );
  };

  if (isLoading) return <Loading />;

  return (
    <Form
      name="complianceSettings"
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{
        company: data.companyAddress?.company,
        countryCode: data.companyAddress?.countryCode,
        addressLine1: data.companyAddress?.addressLine1,
        addressLine2: data.companyAddress?.addressLine2,
        city: data.companyAddress?.city,
        state: data.companyAddress?.state,
        postalCode: data.companyAddress?.postalCode,
      }}
      style={{ padding: '0px 32px', maxWidth: '716px' }}
      onFinish={submit}
    >
      <Text style={{ marginBottom: '16px', display: 'block' }}>
        {t`Company information is used for Communication Hub and Registration invoices. `}
      </Text>
      <Item
        name="company"
        isRequired
        rules={[{ required: true, message: t`Company Name is required` }]}
        label={<Label isRequired>{t`Company Name`}</Label>}
      >
        <Input />
      </Item>
      <Item
        name="countryCode"
        isRequired
        rules={[{ required: true, message: t`Country is required` }]}
        label={<Label isRequired>{t`Country`}</Label>}
      >
        <Select
          options={countries().map(countryItem => ({
            label: `${countryItem.emoji} ${countryItem.name}`,
            value: countryItem.iso2,
          }))}
          showSearch
          filterOption={(v, { label }) => label.toLocaleLowerCase().includes(v.toLocaleLowerCase())}
          onChange={resetAddress}
        />
      </Item>
      <Item
        style={{ marginBottom: 8 }}
        label={<Label isRequired>{t`Address`}</Label>}
        name="addressLine1"
        rules={[
          { required: true, message: t`Address is required` },
          {
            max: 70,
            message: t`Address must be less than 70 characters`,
          },
        ]}
      >
        <Input placeholder={t`Street address`} />
      </Item>
      <Item
        noStyle
        name="addressLine2"
        rules={[
          {
            max: 70,
            message: t`Address must be less than 70 characters`,
          },
        ]}
      >
        <Input style={{ marginBottom: 16 }} placeholder={t`Apartment, Suite, etc (optional)`} />
      </Item>
      <Row gutter={16}>
        <Col span={8}>
          <Item
            label={<Label isRequired>{t`City`}</Label>}
            name="city"
            rules={[{ required: true, message: t`City is required` }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label={<Label isRequired={countryStates?.length > 0}>{countryAreasTerm}</Label>}
            name="state"
            rules={[
              {
                required: countryStates?.length > 0,
                message: t`Please select a ${countryAreasTerm}.`,
              },
            ]}
          >
            {countryStates?.length > 0 ? (
              <Select
                showSearch
                placeholder={t`Select`}
                filterOption={filterByLabelOrValue}
                options={countryStates?.map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
              />
            ) : (
              <Input />
            )}
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label={<Label>{countryDetails?.postalTerm || t`Postal Code`}</Label>}
            name="postalCode"
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <StickyFooter>
        <Button type="primary" htmlType="submit" disabled={isSaving} loading={isSaving}>
          {t`Save Settings`}
        </Button>
        <Button type="default" onClick={resetToDefaults}>
          {t`Cancel`}
        </Button>
      </StickyFooter>
    </Form>
  );
};

export default CompanyInfo;
