import { t } from 'ttag';

export const ITEM_TYPE = {
  Section: 'section',
  CustomField: 'custom_field',
  RichText: 'display_element_richtext',
  ProfileFieldFirstName: 'profile_field_first_name',
  ProfileFieldLastName: 'profile_field_last_name',
  ProfileFieldEmail: 'profile_field_email',
  ProfileFieldPronouns: 'profile_field_pronouns',
  ProfileFieldCompanyName: 'profile_field_company_name',
  ProfileFieldTitle: 'profile_field_title',
  ProfileFieldWebsite: 'profile_field_website',
  ProfileFieldFacebook: 'profile_field_facebook',
  ProfileFieldTwitter: 'profile_field_twitter',
  ProfileFieldLinkedin: 'profile_field_linkedin',
};

export const getStaticFields = () => [
  {
    name: t`First Name`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldFirstName,
  },
  {
    name: t`Last Name`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldLastName,
  },
  {
    name: t`Email Address`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldEmail,
  },
  {
    name: t`Pronouns`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldPronouns,
  },
  {
    name: t`Company`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldCompanyName,
  },
  {
    name: t`Title/Position`,
    type: 'text',
    fieldType: ITEM_TYPE.ProfileFieldTitle,
  },
  {
    name: t`Website`,
    type: 'url',
    fieldType: ITEM_TYPE.ProfileFieldWebsite,
  },
  {
    name: t`Facebook`,
    type: 'url',
    fieldType: ITEM_TYPE.ProfileFieldFacebook,
  },
  {
    name: t`X (formerly Twitter)`,
    type: 'url',
    fieldType: ITEM_TYPE.ProfileFieldTwitter,
  },
  {
    name: t`LinkedIn`,
    type: 'url',
    fieldType: ITEM_TYPE.ProfileFieldLinkedin,
  },
];

export const ERROR_CODES = {
  ItemExists: 'reg_form_item_exists',
};
