import { Tabs, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import useTab from '@hooks/useTab';

import CompanyInfo from './CompanyInfo';
import EventInfo from './EventInfo';

const { Title: AntTitle } = Typography;

const Title = styled(AntTitle)`
  && {
    margin: 46px 32px 69px 32px;
  }
`;

const Wrap = styled.div`
  margin-block: 45px;
`;

const EVENT_INFO_TAB = 'event-info';
const COMPANY_INFO_TAB = 'company-info';

const TAB_DEFAULT = EVENT_INFO_TAB;
const VALID_TABS = [EVENT_INFO_TAB, COMPANY_INFO_TAB];

export default () => {
  const [activeTab, onChange] = useTab(TAB_DEFAULT, VALID_TABS, false);

  return (
    <Wrap>
      <Title>{t`Event Details`}</Title>
      <Tabs
        type="card"
        style={{ overflow: 'visible' }}
        activeKey={activeTab}
        onChange={onChange}
        defaultActiveKey={EVENT_INFO_TAB}
        tabBarStyle={{ marginBottom: 24, paddingLeft: 32 }}
        destroyInactiveTabPane
        items={[
          {
            label: t`Event Info`,
            key: EVENT_INFO_TAB,
            children: <EventInfo />,
          },
          {
            label: t`Company Info`,
            key: COMPANY_INFO_TAB,
            children: <CompanyInfo />,
          },
        ]}
      />
    </Wrap>
  );
};
